import { React, Component } from "react";
import { ToggleButton, Tab, Container, Table, Col, Row, ListGroup, Button, Carousel, Modal, Badge, Form, Nav, InputGroup, FormControl, Accordion, Card, ThemeProvider } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs'
import { BsCaretDownFill, BsCaretUpFill, BsArrowDown } from "react-icons/bs";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import BasicNavbar from '../Navbar/Navbar';
import service from "../../services/service"
import priceDict from "../../pricing";
import timeDict from "../../timings";

import { showConfidence, carousalInterval, displayNoDamage, displayBoundingBox, displayMask, allow_inner_tab } from "../../config.json";
import { ProgressTracker, Stages } from '@atlaskit/progress-tracker';


import './Damage.css';


let colorMap = {
  "replace": "#721c24",
  "minor_repair": "#ecdfcb",
  "damage": "#ecdfcb",
  "major_repair": "#f5c6cb",
  "no_damage": "#c3e6cb",
  "unclear": "#c6c8ca"
}



class Damage extends Component {
  constructor(props) {
    super(props);

    // Fetch result from props.location, convert to list and sort using custom sort method

    let documentResult = this.props.location.documentresult;
    let vinResult = this.props.location.vinresult;
    let result = this.props.location.result;


    if (result) {
      localStorage.setItem('damageResult', JSON.stringify(result));
    } else {
      result = JSON.parse(localStorage.getItem('damageResult'));
    }

    let interiordamageresult = this.props.location.interiordamageresult;

    if (interiordamageresult) {
      localStorage.setItem('interiorDamageResult', JSON.stringify(interiordamageresult));
    } else {
      interiordamageresult = JSON.parse(localStorage.getItem('interiorDamageResult'));
    }



    console.log(interiordamageresult);


    var mock_result = {
      damage_assessment:
      {
        volante:
        {
          images: ["/media/images/00ei09a7zi0etv0j9p1bas_7ngVket.jpg"],
          confidences: ["damage: 0.65"],
          images_clean: ["/media/images/00ei09a7zi0etv0j9p1bas_7ngVket.jpg"],
          images_burned_masked: ["/media/images/00ei09a7zi0etv0j9p1bas_7ngVket.jpg"],
          images_masked: ["/media/images/00ei09a7zi0etv0j9p1bas_7ngVket.jpg"],
          overall_damage_class: 'damage',
          predicted_damage: "quemado",
          predicted_action: "importe",
          damage_measures: ['0.52 cm', '0.71 cm']
        },
        guarnecido_techo:
        {
          images: ["/media/images/00ei09a7zi0etv0j9p1bas_7ngVket.jpg"],
          confidences: ["no_damage: 0.65"],
          images_clean: ["/media/images/00ei09a7zi0etv0j9p1bas_7ngVket.jpg"],
          images_burned_masked: ["/media/images/00ei09a7zi0etv0j9p1bas_7ngVket.jpg"],
          images_masked: ["/media/images/00ei09a7zi0etv0j9p1bas_7ngVket.jpg"],
          overall_damage_class: 'no_damage',
          predicted_damage: "",
          predicted_action: "",
          damage_measures: []
        }
      }
    };

    console.log('*************************');
    console.log('api', interiordamageresult);
    console.log('mock', interiordamageresult);
    console.log('*************************');

    interiordamageresult = interiordamageresult;


    if (documentResult) {
      localStorage.setItem('documentResult', JSON.stringify(documentResult));
      this.documentResult = JSON.parse(documentResult);
    } else {
      documentResult = JSON.parse(localStorage.getItem('documentResult'));
      this.documentResult = JSON.parse(documentResult);
    }

    if (vinResult) {
      localStorage.setItem('vinResult', JSON.stringify(vinResult));
      this.vinResult = JSON.parse(vinResult);
    } else {
      vinResult = JSON.parse(localStorage.getItem('vinResult'));
      this.vinResult = JSON.parse(vinResult);
    }

    this.documentResult['license_plate'] = result["license_plate_info"]["plate_number"];
    this.documentResult['license_plate_country'] = result["license_plate_info"]["country"];
    this.documentResult['make'] = result["license_plate_info"]["make"];
    this.documentResult['model'] = result["license_plate_info"]["model"];

    this.outer_damage_assessment = Object.entries(result["damage_assessment"])
    this.inner_damage_assessment = Object.entries(interiordamageresult["damage_assessment"])

    console.log(JSON.stringify(this.outer_damage_assessment));
    console.log(JSON.stringify(this.inner_damage_assessment));

    this.partList = this.outer_damage_assessment

    this.license_plate = this.partList.filter((element) => {
      return ["front_license_plate"].includes(element[0])
    })
    this.license_plate = this.license_plate.concat(
      this.partList.filter((element) => {
        return ["rear_license_plate"].includes(element[0])
      })
    )

    this.sortParts()
    this.getDamageParts()

    this.checked = false;
    this.setChecked = false;


    this.handleChangeDamage = this.handleChangeDamage.bind(this);
    this.handleChangeBoundingBox = this.handleChangeBoundingBox.bind(this);
    this.handleChangeMask = this.handleChangeMask.bind(this);
    this.handleChangeArrow = this.handleChangeArrow.bind(this);
    this.handledisplayOuter = this.handledisplayOuter.bind(this);


    // By default the Assessment Summary Modal is hidden
    this.state = {
      modalShow: false,
      displayNoDamage: sessionStorage.getItem("displayNoDamage") == "true" ? true : false,
      displayBoundingBox: sessionStorage.getItem("displayBoundingBox") == "false" ? false : true,
      displayMask: sessionStorage.getItem("displayMask") == "false" ? false : true,
      activeKey: "#main", //+ this.partList[0][0]
      arrowDown: true,
      displayOuter: true
    }


    this.items = [
      {
        id: 'current-1',
        label: 'Upload images',
        percentageComplete: 100,
        status: 'visited',
        href: '/',
      },
      {
        id: 'unvisited-1',
        label: 'Classify images',
        percentageComplete: 100,
        status: 'visited',
        href: 'classified',
      },
      {
        id: 'unvisited-2',
        label: 'Assess damage',
        percentageComplete: 0,
        status: 'current',
        href: '#',
      },
    ];
  }

  handleChangeArrow(evt) {
    this.setState({
      arrowDown
        : !this.state.arrowDown
    });
  }

  handleChangeDamage(evt) {
    this.setState({
      displayNoDamage
        : !this.state.displayNoDamage
    });
    sessionStorage.setItem("displayNoDamage", !this.state.displayNoDamage);
  }

  handleChangeBoundingBox(evt) {
    this.setState({
      displayBoundingBox
        : !this.state.displayBoundingBox
    });
    sessionStorage.setItem("displayBoundingBox", !this.state.displayBoundingBox);
  }

  handleChangeMask(evt) {
    this.setState({
      displayMask
        : !this.state.displayMask
    });
    sessionStorage.setItem("displayMask", !this.state.displayMask);
  }

  handledisplayOuter(evt) {

    if ((this.state.displayOuter == true && evt == "inner") || (this.state.displayOuter == false && evt == "outer")) {

      this.partList = !this.state.displayOuter ? this.outer_damage_assessment : this.inner_damage_assessment

      let index = '#main'
      try { index = '#' + this.partList[0][0] } catch (e) { index = '#main' }

      this.setState({
        displayOuter
          : !this.state.displayOuter,
        activeKey: index
      });

      this.sortParts()
      this.getDamageParts()
    }
  }


  downloadImgs() {

    const zip = require('jszip')();

    (async () => {

      for (let file = 0; file < this.partList.length; file++) {

        console.log(file)
        console.log(this.partList[file][1]['images_burned_masked'][0])
        const imageUrl = "https://automapp-api.komorebi.ai" + this.partList[file][1]['images_burned_masked'][0];
        const response = await fetch(imageUrl)
        const imageBlob = await response.blob()
        const reader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          var base64data = reader.result;

          base64data = base64data.replace('data:', '').replace(/^.+,/, '');
          var filename = this.partList[file][1]['images_burned_masked'][0]

          zip.file(filename, base64data, { base64: true })

        }
      }

      zip.generateAsync({ type: "blob" }).then(content => {
        saveAs(content, "images.zip");
      });
    })()

  }

  accessPhotoGivenState(obj) {
    if (this.state.displayBoundingBox && this.state.displayMask) {
      return obj['images_burned_masked']
    } else if (this.state.displayMask && !this.state.displayBoundingBox) {
      return obj['images_masked']
    } else if (!this.state.displayMask && this.state.displayBoundingBox) {
      return obj['images']
    } else {
      return obj['images_clean']
    }
  }

  beautifyName(input) {
    // Splits by underscore and captializss first letter of input string
    if (!input) {
      return ""
    }
    var text = input.replaceAll("_", " ").toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    return text;
  }


  sortParts() {
    // Sort parts firstly baseed on priority of damage class, and then by their name

    const priority = ["replace", "major_repair", "minor_repair", "unclear", "no_damage"]

    function compare(a, b) {
      // Custom compare function, to sort baseed on priority of damage class, and then by their name
      if (priority.indexOf(a[1].overall_damage_class) < priority.indexOf(b[1].overall_damage_class)) {
        return -1;
      } else if (priority.indexOf(a[1].overall_damage_class) > priority.indexOf(b[1].overall_damage_class)) {
        return 1
      } else {
        return a[0].localeCompare(b[0])
      }
    }
    this.partList.sort(compare);
  }

  getDamageParts() {
    // Filter and store damagedParts in a seperate list

    this.damagedParts = this.partList.filter((element) => {
      return ["replace", "major_repair", "minor_repair", "damage"].includes(element[1].overall_damage_class)
    })
    // Assign cost to each damagedPart, calculated totalCost
    this.totalCost = 0;
    this.totalTime = 0;
    this.damagedParts.map((element) => {
      let partCost = 15.00
      let partTime = 1.00
      try {
        partCost = priceDict[element[0]][element[1].overall_damage_class]
        partTime = timeDict[element[0]][element[1].overall_damage_class]
      }
      catch (err) {
        console.log("Don't have the price or time data for this part!")
      }

      this.totalCost = this.totalCost + partCost
      this.totalTime = this.totalTime + partTime
      element.push(priceDict.currency + " " + partCost)
      element.push(partTime + " " + timeDict.unit)
    })
  }

  hideAssessmentSummary() {
    // Hide assessment summary Modal
    this.setState({
      modalShow: false
    })
  }
  showAssessmentSummary() {
    // Show assessment summary Modal
    this.setState({
      modalShow: true
    })
  }

  hideAssessmentDocuments() {
    // Hide assessment summary Modal
    this.setState({
      modalDocumentShow: false
    })
  }
  showAssessmentDocuments() {
    // Show assessment summary Modal
    this.setState({
      modalDocumentShow: true
    })
  }

  onclickHandler(hash,) {
    window.history.replaceState(undefined, undefined, hash)
    this.setState({
      activeKey: hash
    })
  }

  getPartColor(partName) {
    let partColorMap = {};
    this.partList.map((element) => {
      partColorMap[element[0]] = colorMap[element[1]["overall_damage_class"]]
    })
    if (partName in partColorMap) {
      return partColorMap[partName]
    } else {
      return "#555555"
    }
  }

  printDocument() {
    const input = document.getElementById('info-from-documents');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', 'a4', false);
        pdf.addImage('header.png', 'PNG', 50, 30, 500, 0);
        pdf.text("Extracted Info from Documents", 50, 150);
        pdf.addImage(imgData, 'PNG', 50, 160, 500, 0, undefined, false);
        pdf.line(50, 160, 550, 160)
        pdf.text("JSON", 50, 460);
        pdf.line(50, 470, 550, 470)
        pdf.setFontSize(14)
        pdf.text(JSON.stringify(this.documentResult, null, 2), 50, 500)


        if (this.documentResult['photo_url'] !== undefined) {
          pdf.addImage(service.getMediaUrl(this.documentResult['photo_url']), 'PNG', 60, 190, 150, 0)
        }
        else {
          pdf.text("Not detected in photos", 60, 220)
        }

        if (this.license_plate[0] !== undefined) {
          pdf.addImage(service.getMediaUrl(this.license_plate[0][1].images[0].replace("burned.jpg", "cropped.jpg")), 'PNG', 60, 300, 150, 0)
        }
        else {
          pdf.text("Not detected in photos", 60, 330)
        }

        var date = new Date().toLocaleString()
        pdf.save("Insurmapp_Report_" + date + ".pdf");

      })
      ;
  }


  render() {
    return (
      <div>
        <BasicNavbar items={"gggg"} />
        <Container>

          <ProgressTracker items={this.items} />

          <Row className="tabsRow">
            <Tabs defaultActiveKey="outer" className="mb-3" onSelect={this.handledisplayOuter}>
              <Tab eventKey="outer" title="Outer car" >
              </Tab>
              <Tab eventKey="inner" title="Inner car" disabled={!allow_inner_tab || this.inner_damage_assessment.length == 0}>
              </Tab>
            </Tabs>
          </Row>

          <Row className="mt-5">
            <Tab.Container activeKey={this.state.activeKey}>
              <Col sm={3}>
                <ListGroup>
                  <div key="main">
                    {this.state.displayOuter ?
                      <ListGroup.Item active={("#main") === this.state.activeKey} onClick={(e) => { e.preventDefault(); this.onclickHandler("#main") }} action>
                        <span>Overview</span>

                      </ListGroup.Item> : null}
                  </div>

                  {this.partList.map((partObj, index) =>
                  (<div key={index}>
                    {partObj[1].overall_damage_class === "no_damage" && !this.state.displayNoDamage ?
                      <ListGroup.Item active={("#" + partObj[0]) === this.state.activeKey} action variant="success" onClick={(e) => { e.preventDefault(); this.onclickHandler("#" + partObj[0]) }} >
                        <span>{this.beautifyName(partObj[0])} ({this.accessPhotoGivenState(partObj[1]).length})</span>{' '}
                        <span>

                          <Badge pill variant="dark">{this.beautifyName(partObj[1].overall_damage_class)}</Badge>

                        </span>
                      </ListGroup.Item>
                      : partObj[1].overall_damage_class === "no_damage" && this.state.displayNoDamage ?
                        <span></span>
                        : partObj[1].overall_damage_class === "replace" ?
                          <ListGroup.Item action active={("#" + partObj[0]) === this.state.activeKey} variant="danger" onClick={(e) => { e.preventDefault(); this.onclickHandler("#" + partObj[0]) }} >
                            <span>{this.beautifyName(partObj[0])}  ({this.accessPhotoGivenState(partObj[1]).length})</span>{' '}
                            <span>
                              <Badge pill variant="dark">{this.beautifyName(partObj[1].overall_damage_class)}</Badge>
                            </span>
                          </ListGroup.Item>
                          : partObj[1].overall_damage_class === "major_repair" || partObj[1].overall_damage_class === "damage" ?
                            <div>
                              <style type="text/css">
                                {`
                            .list-group-item-major {
                              color: #bf5e13;
                              background-color: #eed7c8;
                          }
                          .list-group-item-minor.active {
                              background-color:#bf5e13;
                          }
                            `}
                              </style>
                              <ListGroup.Item action active={("#" + partObj[0]) === this.state.activeKey} variant="major" onClick={(e) => { e.preventDefault(); this.onclickHandler("#" + partObj[0]) }} >
                                <span>{this.beautifyName(partObj[0])} ({this.accessPhotoGivenState(partObj[1]).length})</span>{' '}
                                <span>
                                  <Badge pill variant="dark">{this.beautifyName(partObj[1].overall_damage_class)}</Badge>
                                </span>
                              </ListGroup.Item>
                            </div>
                            : partObj[1].overall_damage_class === "minor_repair" ?
                              <div>
                                <style type="text/css">
                                  {`
                            .list-group-item-minor {
                              color: #c4990c;
                              background-color: #eee3c8;
                          }
                          .list-group-item-minor.active {
                              background-color:#c4990c;
                          }
                            `}
                                </style>
                                <ListGroup.Item action active={("#" + partObj[0]) === this.state.activeKey} variant="minor" onClick={(e) => { e.preventDefault(); this.onclickHandler("#" + partObj[0]) }} >
                                  <span>{this.beautifyName(partObj[0])} ({this.accessPhotoGivenState(partObj[1]).length})</span>{' '}
                                  <span>
                                    <Badge pill variant="dark">{this.beautifyName(partObj[1].overall_damage_class)}</Badge>
                                  </span>
                                </ListGroup.Item>
                              </div>
                              :
                              <ListGroup.Item action active={("#" + partObj[0]) === this.state.activeKey} variant="dark" onClick={(e) => { e.preventDefault(); this.onclickHandler("#" + partObj[0]) }} >
                                <span>{this.beautifyName(partObj[0])} ({this.accessPhotoGivenState(partObj[1]).length})</span>{' '}
                                <span>
                                  <Badge pill variant="dark">{this.beautifyName(partObj[1].overall_damage_class)}</Badge>
                                </span>
                              </ListGroup.Item>
                    }
                  </div>)
                  )}
                </ListGroup>
                <Row className="justify-content-center">
                  <Button className="downloadButton" onClick={() => this.downloadImgs()}>Download Images <BsArrowDown /></Button>
                </Row>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="#main" key="main">
                    <svg viewBox="0 0 922 595" xmlns="http://www.w3.org/2000/svg" >
                      <g
                        transform="matrix(0.1, 0, 0, -0.1, 0, 595)"
                        fill="#000000"
                        stroke="none"
                      >

                        <path
                          d="M 2521 5916 C 2610 5897 2662 5869 2730 5804 C 2824 5715 2866 5605 2858 5471 C 2846 5264 2680 5097 2472 5083 C 2335 5073 2227 5115 2135 5211 C 1880 5478 2061 5910 2435 5929 C 2446 5929 2485 5923 2521 5916"
                          id="front_right_wheel" fill={this.getPartColor("front_right_wheel")}
                        />
                        <path
                          d="M2321 5729 c-66 -33 -107 -82 -132 -157 -15 -47 -6 -139 20 -189 40 -79 145 -143 233 -143 38 0 122 33 164 65 41 31 82 108 90 169 9 63 -19 148 -64 198 -82 91 -199 113 -311 57z m188 5 c150 -44 218 -226 135 -360 -66 -107 -202 -145 -314 -87 -148 76 -181 260 -67 380 29 30 60 50 97 63 68 24 83 24 149 4z"
                          style={{
                            fill: "rgb(198, 198, 198)"
                          }}
                        />
                        <path
                          d="M 6471 5916 C 6560 5897 6612 5869 6680 5804 C 6775 5714 6817 5604 6807 5469 C 6797 5317 6712 5192 6571 5122 C 6499 5086 6492 5085 6391 5085 C 6291 5085 6281 5087 6213 5119 C 6126 5159 6041 5243 6005 5322 C 5878 5601 6074 5913 6385 5929 C 6396 5929 6435 5923 6471 5916"
                          id="rear_right_wheel" fill={this.getPartColor("rear_right_wheel")}
                        />
                        <path
                          d="M6271 5729 c-66 -33 -107 -82 -132 -157 -15 -47 -6 -139 20 -189 40 -79 145 -143 233 -143 37 0 122 33 161 63 41 31 85 112 93 169 9 65 -18 149 -64 200 -82 91 -199 113 -311 57z m188 5 c150 -44 218 -226 135 -360 -66 -107 -202 -145 -314 -87 -149 76 -181 259 -67 380 29 30 60 50 97 63 68 24 83 24 149 4z"
                          style={{
                            fill: "rgb(255, 255, 255)"
                          }}
                        />
                        <path
                          d="M 7516 5053 C 7522 5048 7533 4991 7539 4925 C 7545 4859 7553 4790 7556 4773 C 7563 4737 7559 4737 7687 4760 C 7735 4768 7740 4766 7740 4734 C 7740 4669 7640 4615 7426 4564 C 7348 4546 7247 4522 7200 4510 C 7133 4494 7031 4449 6710 4291 C 6487 4181 6280 4082 6249 4072 C 6169 4043 6045 4015 5890 3990 C 5816 3979 5747 3968 5738 3966 C 5728 3964 5720 3963 5720 3965 C 5720 3966 5758 4005 5805 4051 C 5963 4204 6203 4507 6266 4631 C 6323 4744 6319 4823 6253 4880 L 6217 4912 L 6251 4905 C 6305 4892 6490 4895 6550 4909 C 6623 4926 6738 4981 6789 5024 L 6830 5059 L 7168 5059 C 7353 5060 7510 5057 7516 5053 Z"
                          id="right_quarter_panel" fill={this.getPartColor("right_quarter_panel")}
                        />
                        <path
                          d="M 5989 5018 C 6011 4999 6069 4962 6117 4934 C 6274 4846 6299 4803 6259 4696 C 6233 4626 6134 4477 6013 4325 C 5896 4178 5694 3976 5649 3962 C 5591 3943 5358 3930 5094 3930 L 4842 3930 L 4836 4068 C 4827 4257 4811 4479 4794 4640 C 4787 4714 4780 4838 4780 4915 L 4780 5055 L 5364 5054 L 5949 5053 L 5989 5018 Z"
                          id="rear_right_door" fill={this.getPartColor("rear_right_door")}
                        />
                        <path
                          d="M 4731 5051 C 4745 5050 4748 5026 4753 4868 C 4756 4767 4766 4622 4774 4545 C 4787 4426 4810 4051 4810 3956 L 4810 3927 L 4603 3934 C 4351 3942 4219 3955 4123 3981 C 4052 3999 3832 4117 3475 4326 C 3184 4496 3209 4475 3178 4568 C 3143 4673 3121 4797 3113 4937 L 3107 5049 L 3141 5052 C 3167 5054 4658 5053 4731 5051 Z"
                          id="front_right_door" fill={this.getPartColor("front_right_door")}
                        />
                        <path
                          d="M 2032 5019 C 2159 4919 2272 4886 2465 4892 C 2578 4896 2604 4901 2665 4925 C 2734 4952 2804 4994 2846 5032 C 2868 5051 2908 5055 3036 5051 L 3077 5050 L 3084 4938 C 3092 4801 3112 4681 3144 4578 C 3157 4535 3166 4500 3164 4500 C 3162 4500 3128 4509 3088 4520 C 2984 4548 2839 4568 2575 4590 C 2208 4622 2121 4630 2020 4646 C 1867 4670 1709 4714 1600 4762 L 1545 4787 L 1648 4794 C 1739 4801 1751 4804 1745 4818 C 1742 4828 1724 4878 1705 4930 C 1687 4982 1669 5033 1665 5043 C 1658 5059 1670 5060 1819 5060 L 1981 5060 L 2032 5019 Z"
                          id="right_fender" fill={this.getPartColor("right_fender")}
                        />
                        <path
                          d="M 7745 5374 C 7774 5345 7785 5283 7773 5214 C 7760 5140 7750 5124 7704 5105 C 7638 5078 7527 5070 7190 5070 L 6848 5070 L 6870 5098 C 6923 5166 6971 5246 6996 5310 C 7016 5361 7028 5380 7044 5383 C 7055 5385 7215 5387 7398 5388 C 7677 5388 7733 5386 7745 5374 Z"
                          id="rear_bumper" fill={this.getPartColor("rear_bumper")}
                        />
                        <path
                          d="M 5909 5325 C 5971 5194 6074 5090 6195 5033 C 6251 5007 6264 5006 6390 5005 C 6521 5005 6527 5006 6590 5036 C 6626 5053 6678 5088 6707 5114 C 6762 5164 6837 5268 6869 5341 L 6889 5385 L 6945 5386 C 7008 5386 7009 5384 6979 5308 C 6880 5056 6665 4910 6392 4910 C 6213 4910 6092 4958 5974 5076 C 5916 5133 5893 5167 5846 5259 C 5815 5321 5792 5375 5794 5378 C 5796 5382 5817 5385 5839 5385 C 5879 5385 5881 5384 5909 5325 Z"
                          id="right_quarter_panel" fill={this.getPartColor("right_quarter_panel")}

                        />
                        <path
                          d="M 5750 5380 C 5756 5376 5779 5334 5800 5286 C 5821 5239 5859 5171 5885 5135 L 5931 5070 L 5356 5070 L 4780 5070 L 4781 5113 C 4782 5204 4807 5372 4821 5380 C 4837 5391 5733 5391 5750 5380 Z"
                          id="rear_right_door" fill={this.getPartColor("rear_right_door")}
                        />
                        <path
                          d="M 4780 5386 C 4780 5385 4773 5330 4765 5265 C 4757 5199 4750 5128 4750 5108 L 4750 5070 L 3929 5070 L 3108 5070 L 3115 5138 C 3123 5232 3142 5374 3146 5382 C 3150 5387 4780 5392 4780 5386 Z"
                          id="front_right_door" fill={this.getPartColor("front_right_door")}
                        />
                        <path
                          d="M 3110 5372 C 3110 5364 3105 5319 3100 5271 C 3094 5224 3087 5159 3084 5128 L 3079 5070 L 2984 5070 L 2890 5070 L 2931 5123 C 2971 5173 3050 5321 3050 5346 C 3050 5364 3075 5387 3094 5387 C 3103 5387 3110 5380 3110 5372 Z"
                          id="right_fender" fill={this.getPartColor("right_fender")}
                        />
                        <path
                          d="M 1950 5302 C 2020 5162 2127 5064 2257 5021 C 2335 4995 2496 4992 2585 5015 C 2737 5055 2862 5169 2936 5336 C 2960 5390 2960 5390 3001 5388 C 3040 5385 3041 5384 3036 5355 C 3033 5339 3011 5287 2988 5240 C 2904 5072 2750 4952 2570 4914 C 2480 4895 2324 4900 2240 4925 C 2093 4969 1965 5076 1893 5215 C 1821 5354 1819 5394 1882 5387 C 1905 5385 1915 5372 1950 5302 Z"
                          id="right_fender" fill={this.getPartColor("right_fender")}
                        />
                        <path
                          d="M 1829 5330 C 1850 5262 1892 5179 1930 5126 C 1947 5104 1958 5085 1956 5083 C 1954 5081 1817 5078 1651 5076 C 1379 5073 1347 5074 1328 5090 C 1310 5104 1306 5123 1298 5220 C 1291 5314 1292 5336 1304 5348 C 1333 5377 1421 5388 1619 5386 L 1812 5385 L 1829 5330 Z"
                          id="bumper" fill={this.getPartColor("bumper")}
                        />
                        <path
                          d="M 7596 5566 C 7608 5553 7623 5518 7630 5488 C 7637 5459 7649 5427 7658 5418 C 7672 5401 7654 5400 7287 5400 C 7074 5400 6900 5402 6900 5405 C 6900 5413 6949 5581 6954 5588 C 6956 5592 7097 5594 7267 5593 L 7575 5591 L 7596 5566 Z"
                          id="rear_bumper" fill={this.getPartColor("rear_bumper")}
                        />
                        <path
                          d="M 5838 5523 C 5849 5480 5862 5435 5865 5423 C 5872 5401 5869 5400 5827 5400 L 5782 5400 L 5753 5493 C 5736 5543 5725 5588 5727 5593 C 5729 5597 5751 5600 5774 5600 L 5818 5600 L 5838 5523 Z"
                          id="right_rocker_panel" fill={this.getPartColor("right_rocker_panel")}

                        />
                        <path
                          d="M 5716 5513 C 5731 5464 5745 5419 5747 5413 C 5751 5403 5654 5400 5280 5400 L 4808 5400 L 4814 5428 C 4817 5443 4824 5484 4831 5520 C 4837 5556 4844 5588 4846 5592 C 4849 5597 5040 5600 5270 5600 L 5689 5600 L 5716 5513 Z"
                          id="right_rocker_panel" fill={this.getPartColor("right_rocker_panel")}
                        />
                        <path
                          d="M 4810 5588 C 4810 5581 4803 5537 4795 5490 C 4787 5443 4780 5404 4780 5403 C 4780 5401 4413 5400 3964 5400 C 3192 5400 3149 5401 3154 5418 C 3157 5427 3162 5449 3166 5465 C 3176 5510 3220 5588 3239 5593 C 3248 5596 3605 5598 4033 5599 C 4648 5600 4810 5597 4810 5588 Z"
                          id="right_rocker_panel" fill={this.getPartColor("right_rocker_panel")}
                        />
                        <path
                          d="M 3183 5594 C 3186 5591 3180 5574 3170 5557 C 3160 5539 3144 5497 3135 5463 L 3117 5400 L 3038 5400 C 2966 5400 2959 5402 2965 5418 C 2968 5427 2978 5467 2987 5505 C 2995 5544 3003 5581 3005 5588 C 3009 5600 3170 5606 3183 5594 Z"
                          id="right_rocker_panel" fill={this.getPartColor("right_rocker_panel")}
                        />
                        <path
                          d="M 1864 5583 C 1867 5573 1873 5540 1879 5510 C 1884 5480 1893 5443 1899 5428 L 1910 5400 L 1665 5400 C 1530 5400 1420 5402 1420 5405 C 1420 5413 1501 5574 1512 5588 C 1520 5597 1568 5600 1691 5600 C 1842 5600 1859 5598 1864 5583 Z"
                          id="bumper" fill={this.getPartColor("bumper")}
                        />
                        <path
                          d="M 3280 4532 C 3280 4497 3549 4317 3835 4160 C 4029 4053 4129 4010 4218 3994 C 4297 3980 4756 3953 4765 3962 C 4770 3967 4732 4506 4725 4528 C 4721 4537 4568 4540 4000 4540 C 3604 4540 3280 4536 3280 4532 Z"
                          style={{
                            fill: "rgb(255, 255, 255)"
                          }}
                        />
                        <path
                          d="M 4700 4495 C 4700 4475 4728 4101 4735 4036 L 4739 3988 L 4582 3995 C 4392 4003 4254 4016 4180 4032 C 4113 4047 3874 4166 3682 4280 L 3540 4365 L 3540 4438 L 3540 4510 L 4120 4510 C 4633 4510 4700 4508 4700 4495 Z"
                          id="front_right_door" fill={this.getPartColor("front_right_door")}

                        />
                        <path
                          d="M 4865 4423 C 4869 4358 4875 4231 4878 4140 L 4885 3975 L 5185 3972 C 5508 3969 5602 3977 5659 4013 C 5714 4046 5785 4116 5860 4210 C 5937 4307 6070 4507 6070 4527 C 6070 4538 5955 4540 5464 4540 L 4859 4540 L 4865 4423"
                          style={{
                            fill: "rgb(255, 255, 255)"
                          }}
                        />
                        <path
                          d="M 6020 4500 C 6020 4489 5863 4253 5850 4245 C 5844 4241 5840 4288 5840 4374 L 5840 4510 L 5930 4510 C 5981 4510 6020 4506 6020 4500"
                          id="rear_right_door" fill={this.getPartColor("rear_right_door")}
                        />
                        <path
                          d="M 5824 4494 C 5827 4485 5830 4420 5830 4349 L 5830 4220 L 5778 4159 C 5703 4071 5629 4022 5555 4010 C 5522 4005 5364 4000 5204 4000 L 4913 4000 L 4906 4168 C 4903 4260 4897 4374 4894 4423 L 4887 4510 L 5353 4510 C 5768 4510 5819 4508 5824 4494"
                          id="rear_right_door" fill={this.getPartColor("rear_right_door")}>

                          <img title="tooltip text" src="http://automapp.komorebi.ai:8888/media/parts/941/8_IMG_2775_bpDRSNh.JPG_upper_bumper_grill_cropped.jpg"></img>




                        </path>
                        <path
                          d="M6386 4543 l-59 -4 -227 -232 c-125 -128 -240 -247 -255 -264 l-27 -33 29 0 c69 0 438 115 578 179 113 53 569 314 605 347 12 12 -32 14 -285 12 -165 -1 -326 -3 -359 -5z m544 -30 c-1 -10 -376 -227 -487 -282 -123 -61 -524 -193 -540 -178 -2 3 95 107 217 231 l222 225 51 4 c103 7 537 7 537 0z"
                          style={{
                            fill: "rgb(255, 255, 255)"
                          }}
                        />
                        <path
                          d="M 8508 1817 C 8511 1803 8410 1740 8386 1740 C 8365 1740 8169 1825 8168 1834 C 8168 1839 8204 1882 8249 1931 L 8331 2020 L 8418 1924 C 8466 1871 8506 1823 8508 1817 Z"
                          id="left_taillamp" fill={this.getPartColor("left_taillamp")}
                        />
                        <path
                          d="M 8508 1939 L 8505 1848 L 8423 1940 L 8341 2032 L 8423 2121 L 8505 2209 L 8508 2120 C 8509 2070 8509 1989 8508 1939 Z"
                          id="left_taillamp" fill={this.getPartColor("left_taillamp")}
                        />
                        <path
                          d="M 8240 2120 L 8322 2030 L 8243 1945 C 8200 1898 8162 1860 8158 1860 C 8153 1860 8150 1939 8150 2035 C 8150 2131 8152 2210 8154 2210 C 8156 2210 8195 2170 8240 2120 Z"
                          id="left_taillamp" fill={this.getPartColor("left_taillamp")}
                        />
                        <path
                          d="M 8475 2198 C 8412 2128 8335 2050 8329 2050 C 8326 2050 8286 2091 8241 2140 L 8158 2230 L 8331 2230 L 8504 2230 L 8475 2198 Z"
                          id="left_taillamp" fill={this.getPartColor("left_taillamp")}
                        />
                        <path
                          d="M 8300 2985 L 8300 2570 L 8270 2570 L 8240 2570 L 8240 2985 L 8240 3400 L 8270 3400 L 8300 3400 L 8300 2985 Z"
                          id="rear_license_plate" fill={this.getPartColor("rear_license_plate")}
                        />
                        <path
                          d="M 8510 2975 L 8510 2260 L 8480 2260 L 8450 2260 L 8450 2968 C 8450 3358 8453 3680 8457 3683 C 8460 3687 8474 3690 8487 3690 L 8510 3690 L 8510 2975 Z"
                          id="trunk" fill={this.getPartColor("trunk")}
                        />
                        <path
                          d="M 8440 2975 L 8440 2260 L 8360 2260 L 8280 2260 L 8280 2406 C 8280 2543 8281 2552 8300 2557 C 8320 2562 8320 2571 8320 2986 C 8320 3403 8320 3410 8300 3410 C 8281 3410 8280 3417 8280 3550 L 8280 3690 L 8360 3690 L 8440 3690 L 8440 2975 Z"
                          id="trunk" fill={this.getPartColor("trunk")}
                        />
                        <path
                          d="M 8268 3548 C 8271 3413 8270 3411 8248 3408 L 8225 3405 L 8222 2983 L 8220 2561 L 8242 2558 C 8265 2555 8265 2552 8268 2407 L 8271 2259 L 8213 2262 L 8155 2265 L 8152 2965 C 8151 3350 8152 3671 8155 3678 C 8158 3687 8176 3690 8212 3688 L 8265 3685 L 8268 3548 Z"
                          id="trunk" fill={this.getPartColor("trunk")}
                        />
                        <path
                          d="M 8414 3805 L 8491 3720 L 8346 3720 C 8267 3720 8194 3717 8184 3713 C 8174 3709 8169 3711 8171 3718 C 8177 3733 8320 3890 8329 3890 C 8333 3890 8371 3852 8414 3805 Z"
                          id="right_taillamp" fill={this.getPartColor("right_taillamp")}
                        />
                        <path
                          d="M 8243 3994 L 8319 3908 L 8243 3824 C 8200 3778 8162 3740 8158 3740 C 8154 3740 8150 3817 8150 3910 C 8150 4004 8154 4080 8158 4080 C 8163 4080 8201 4041 8243 3994 Z"
                          id="right_taillamp" fill={this.getPartColor("right_taillamp")}
                        />
                        <path
                          d="M 8510 3906 L 8510 3721 L 8458 3778 C 8429 3809 8391 3851 8372 3871 L 8338 3908 L 8422 3999 C 8467 4049 8506 4090 8508 4090 C 8509 4090 8510 4007 8510 3906 Z"
                          id="right_taillamp" fill={this.getPartColor("right_taillamp")}
                        />
                        <path
                          d="M 8821 4140 C 8840 4134 8867 4118 8881 4104 L 8905 4078 L 8905 2996 C 8905 1979 8904 1913 8887 1887 C 8866 1855 8804 1821 8758 1818 L 8725 1815 L 8722 2983 L 8720 4150 L 8752 4150 C 8770 4150 8801 4145 8821 4140 Z"
                          id="rear_bumper" fill={this.getPartColor("rear_bumper")}
                        />
                        <path
                          d="M 8688 4207 C 8707 4192 8706 1792 8686 1765 C 8668 1740 8603 1739 8567 1763 L 8540 1780 L 8540 2971 C 8540 3803 8543 4167 8551 4181 C 8573 4222 8650 4237 8688 4207 Z"
                          id="rear_bumper" fill={this.getPartColor("rear_bumper")}
                        />
                        <path
                          d="M 8510 4142 C 8510 4117 8488 4087 8388 3983 L 8328 3920 L 8281 3973 C 8171 4094 8156 4113 8165 4117 C 8171 4119 8222 4147 8279 4179 L 8382 4237 L 8446 4202 C 8497 4173 8510 4161 8510 4142 Z"
                          id="right_taillamp" fill={this.getPartColor("right_taillamp")}
                        />
                        <path
                          d="M 1012.473 1881.04 C 1012.473 1870.04 1001.473 1849.04 988.473 1833.04 C 965.473 1807.04 960.473 1805.04 858.473 1802.04 L 752.473 1798.04 L 752.473 1849.04 L 752.473 1900.04 L 882.473 1900.04 C 1004.473 1900.04 1012.473 1899.04 1012.473 1881.04 Z"
                          id="left_fender" fill={this.getPartColor("left_fender")}
                        />
                        <path
                          d="M 1012.473 2070.04 L 1012.473 1920.04 L 882.473 1920.04 L 752.473 1920.04 L 752.473 2070.04 L 752.473 2220.04 L 882.473 2220.04 L 1012.473 2220.04 L 1012.473 2070.04 Z"
                          id="left_headlamp" fill={this.getPartColor("left_headlamp")}
                        />
                        <path
                          d="M 1002.473 3610.04 C 1002.473 3529.04 1000.473 3519.04 978.473 3497.04 C 959.473 3478.04 953.473 3459.04 948.473 3404.04 C 940.473 3306.04 947.473 2487.04 956.473 2471.04 C 960.473 2464.04 975.473 2455.04 988.473 2450.04 C 1011.473 2441.04 1012.473 2437.04 1012.473 2335.04 L 1012.473 2230.04 L 962.473 2230.04 L 912.473 2230.04 L 912.473 2958.04 C 912.473 3359.04 915.473 3690.04 919.473 3693.04 C 922.473 3697.04 943.473 3700.04 964.473 3700.04 L 1002.473 3700.04 L 1002.473 3610.04 Z"
                          id="upper_bumper_grill" fill={this.getPartColor("upper_bumper_grill")}
                        />
                        <path
                          d="M 892.473 2965.04 C 892.473 2237.04 892.473 2230.04 872.473 2230.04 C 852.473 2230.04 852.473 2237.04 852.473 2965.04 C 852.473 3693.04 852.473 3700.04 872.473 3700.04 C 892.473 3700.04 892.473 3693.04 892.473 2965.04 Z"
                          id="upper_bumper_grill" fill={this.getPartColor("upper_bumper_grill")}
                        />
                        <path
                          d="M 836.473 3678.04 C 844.473 3648.04 844.473 2316.04 836.473 2268.04 C 831.473 2240.04 825.473 2230.04 811.473 2230.04 C 793.473 2230.04 792.473 2254.04 792.473 2959.04 C 792.473 3691.04 792.473 3695.04 823.473 3699.04 C 827.473 3700.04 832.473 3690.04 836.473 3678.04 Z"
                          id="upper_bumper_grill" fill={this.getPartColor("upper_bumper_grill")}
                        />
                        <path
                          d="M 780.473 2962.04 C 782.473 2351.04 780.473 2230.04 769.473 2230.04 C 759.473 2230.04 753.473 2255.04 749.473 2328.04 C 740.473 2458.04 740.473 3685.04 749.473 3694.04 C 775.473 3720.04 777.473 3668.04 780.473 2962.04 Z"
                          id="upper_bumper_grill" fill={this.getPartColor("upper_bumper_grill")}
                        />
                        <path
                          d="M 1012.473 3931.04 C 1012.473 3877.04 1009.473 3805.04 1006.473 3771.04 L 999.473 3710.04 L 871.473 3710.04 L 742.473 3710.04 L 742.473 3870.04 L 742.473 4030.04 L 877.473 4030.04 L 1012.473 4030.04 L 1012.473 3931.04 Z"
                          id="right_headlamp" fill={this.getPartColor("right_headlamp")}
                        />
                        <path
                          d="M 933.473 4138.04 C 975.473 4126.04 996.473 4107.04 1006.473 4073.04 L 1013.473 4050.04 L 878.473 4050.04 L 742.473 4050.04 L 742.473 4100.04 L 742.473 4150.04 L 818.473 4150.04 C 860.473 4150.04 912.473 4145.04 933.473 4138.04 Z"
                          id="right_fender" fill={this.getPartColor("right_fender")}
                        />
                        <path
                          d="M 532.473 2974.04 L 532.473 1799.04 L 510.473 1805.04 C 442.473 1824.04 400.473 1845.04 377.473 1871.04 L 352.473 1902.04 L 352.473 2984.04 C 352.473 4063.04 352.473 4067.04 373.473 4094.04 C 393.473 4119.04 463.473 4148.04 510.473 4149.04 L 532.473 4150.04 L 532.473 2974.04 Z"
                          id="bumper" fill={this.getPartColor("bumper")}
                        />
                        <path
                          d="M 671.473 4205.04 C 687.473 4197.04 702.473 4184.04 706.473 4175.04 C 709.473 4166.04 715.473 3624.04 718.473 2971.04 L 725.473 1784.04 L 694.473 1762.04 C 661.473 1738.04 594.473 1732.04 574.473 1752.04 C 565.473 1761.04 562.473 2048.04 562.473 2982.04 C 562.473 4130.04 563.473 4200.04 580.473 4209.04 C 604.473 4224.04 639.473 4222.04 671.473 4205.04 Z"
                          id="bumper" fill={this.getPartColor("bumper")}
                        />
                        <path
                          d="M 1660 2481 C 1778 2437 2037 2342 2235 2270 C 3010 1987 3086 1959 3105 1950 C 3117 1945 2810 1942 2335 1943 L 1545 1945 L 1538 1995 C 1494 2298 1456 2519 1444 2538 C 1436 2550 1433 2560 1437 2560 C 1441 2560 1541 2524 1660 2481 Z"
                          id="bonnet" fill={this.getPartColor("bonnet")}
                        />
                        <path
                          d="M 4270 3791 C 4785 3768 5231 3770 5796 3796 L 5946 3803 L 5916 3664 L 5887 3525 L 5883 3050 C 5878 2548 5882 2475 5924 2270 C 5937 2207 5946 2153 5944 2151 C 5942 2149 5827 2153 5688 2159 C 5161 2182 4743 2183 4355 2162 C 4157 2150 3994 2143 3991 2145 C 3983 2153 3969 2250 3955 2390 C 3934 2592 3934 3394 3955 3567 C 3982 3799 3985 3811 4013 3806 C 4025 3803 4141 3796 4270 3791 Z"
                          id="roof" fill={this.getPartColor("roof")}
                        />
                        <path
                          d="M 3110 3820 C 3078 3670 3071 3537 3066 2963 C 3061 2387 3064 2332 3111 2135 C 3121 2091 3132 2044 3135 2030 C 3150 1966 3038 2207 2993 2335 C 2942 2482 2940 2510 2940 2988 C 2940 3485 2940 3479 3012 3665 C 3055 3774 3122 3922 3126 3917 C 3128 3915 3121 3871 3110 3820 Z"
                          id="bonnet" fill={this.getPartColor("bonnet")}
                        />
                        <path
                          d="M 3104 3910 C 3043 3787 2976 3620 2951 3530 C 2931 3459 2930 3429 2930 2982 C 2930 2538 2931 2503 2950 2428 C 2976 2328 3034 2181 3101 2049 C 3139 1972 3147 1949 3133 1955 C 3100 1968 1714 2477 1483 2560 L 1420 2583 L 1420 2762 C 1420 2930 1421 2942 1438 2935 C 1466 2923 1531 2907 1534 2911 C 1535 2913 1553 2937 1573 2964 L 1610 3012 L 1578 3057 C 1531 3122 1528 3123 1475 3106 C 1450 3097 1427 3090 1424 3090 C 1422 3090 1421 3158 1422 3242 L 1425 3394 L 2225 3676 C 2665 3831 3050 3967 3080 3978 C 3110 3990 3138 3999 3142 3999 C 3146 4000 3129 3959 3104 3910 Z"
                          id="bonnet" fill={this.getPartColor("bonnet")}
                        />
                        <path
                          d="M 7744 4003 C 7754 3971 7799 3668 7810 3555 C 7825 3414 7818 2434 7802 2340 C 7796 2304 7784 2221 7775 2155 C 7766 2089 7755 2011 7749 1983 L 7739 1930 L 7450 1930 L 7161 1930 L 7190 1995 C 7225 2073 7271 2217 7296 2325 C 7313 2398 7315 2456 7315 3000 L 7315 3595 L 7283 3712 C 7266 3777 7231 3872 7206 3925 L 7161 4020 L 7450 4020 C 7716 4020 7739 4019 7744 4003 Z"
                          id="trunk" fill={this.getPartColor("trunk")}
                        />
                        <path
                          d="M 7170 3968 C 7211 3896 7258 3764 7283 3650 C 7303 3560 7304 3527 7305 3000 C 7305 2505 7303 2435 7287 2351 C 7266 2240 7210 2070 7169 1987 C 7141 1933 7137 1929 7112 1934 C 7097 1938 6889 1976 6650 2020 C 6411 2064 6181 2106 6140 2114 C 6099 2122 6046 2132 6023 2135 C 5983 2141 5981 2143 5971 2188 C 5915 2430 5910 2502 5910 2988 C 5910 3458 5915 3525 5960 3719 L 5981 3807 L 6073 3824 C 6124 3833 6379 3881 6640 3930 C 6901 3978 7121 4019 7127 4019 C 7134 4020 7153 3996 7170 3968 Z"
                          id="rear_windshield" fill={this.getPartColor("rear_windshield")}
                        />
                        <path
                          d="M 3956 3807 C 3957 3806 3952 3769 3945 3725 C 3913 3520 3908 3406 3913 2905 C 3916 2512 3921 2380 3934 2290 C 3943 2227 3951 2166 3953 2155 C 3955 2138 3943 2132 3865 2112 C 3657 2060 3187 1949 3185 1952 C 3178 1960 3118 2236 3105 2320 C 3097 2374 3090 2482 3090 2568 L 3090 2721 L 3170 2803 L 3250 2885 L 3250 2688 C 3250 2563 3254 2490 3260 2490 C 3266 2490 3270 2603 3270 2810 C 3270 3017 3266 3130 3260 3130 C 3254 3130 3250 3086 3250 3017 L 3250 2904 L 3173 2827 C 3131 2785 3096 2750 3096 2750 C 3095 2750 3096 2900 3098 3083 L 3102 3415 L 3178 3493 L 3255 3571 L 3260 3373 C 3265 3210 3266 3232 3266 3500 C 3266 3679 3264 3773 3263 3709 L 3259 3594 L 3179 3514 L 3098 3435 L 3104 3546 C 3111 3671 3165 3977 3185 4011 C 3188 4016 3949 3815 3956 3807 Z"
                          id="front_windshield" fill={this.getPartColor("front_windshield")}
                        />
                        <path
                          d="M 3140 4013 C 3132 4010 2959 3949 2755 3878 C 2552 3806 2315 3723 2230 3693 C 2145 3663 1987 3608 1880 3570 C 1773 3533 1634 3483 1572 3461 C 1509 3438 1456 3420 1453 3420 C 1450 3420 1451 3428 1454 3437 C 1458 3446 1476 3567 1496 3704 C 1515 3842 1533 3970 1536 3988 L 1541 4020 L 2348 4019 C 2792 4019 3148 4016 3140 4013 Z"
                          id="bonnet" fill={this.getPartColor("bonnet")}
                        />
                        <path
                          d="M6555 3864 c-77 -15 -294 -68 -365 -89 -25 -7 -73 -21 -107 -30 -71 -20 -66 -8 -98 -216 -23 -149 -17 -1052 8 -1198 9 -51 18 -95 20 -97 7 -8 345 -95 467 -121 103 -22 159 -27 313 -31 l187 -4 0 901 0 901 -177 -1 c-106 -1 -206 -7 -248 -15z m395 -356 l0 -343 -90 90 -89 89 -4 115 c-1 64 -3 -30 -3 -209 0 -179 2 -235 3 -125 l3 199 90 -89 90 -90 0 -522 0 -523 -87 0 c-102 0 -235 14 -343 36 -144 29 -474 115 -481 125 -3 6 -14 69 -23 142 -16 115 -17 195 -13 627 4 437 7 506 23 585 l19 90 65 18 c118 32 384 98 450 112 36 7 138 13 228 14 l162 1 0 -342z"
                          id="rear_windshield" fill={this.getPartColor("rear_windshield")}
                        />
                        <path
                          d="M 7656 548 C 7649 535 7637 501 7630 471 C 7623 441 7607 406 7594 393 L 7571 370 L 7262 370 L 6954 370 L 6927 459 C 6912 509 6900 554 6900 559 C 6900 567 7022 570 7285 570 L 7670 570 L 7656 548 Z"
                          id="rear_bumper" fill={this.getPartColor("rear_bumper")}
                        />
                        <path
                          d="M 5870 564 C 5870 560 5861 527 5851 491 C 5841 455 5829 413 5826 398 C 5820 372 5816 370 5771 370 L 5722 370 L 5750 462 C 5766 512 5785 557 5792 561 C 5807 571 5870 573 5870 564 Z"
                          id="left_rocker_panel" fill={this.getPartColor("left_rocker_panel")}
                        />
                        <path
                          d="M 5744 562 C 5747 557 5736 513 5720 464 L 5692 375 L 5266 372 C 5032 371 4840 373 4840 377 C 4840 382 4833 423 4825 469 C 4813 536 4813 556 4823 562 C 4839 572 5737 572 5744 562 Z"
                          id="left_rocker_panel" fill={this.getPartColor("left_rocker_panel")}
                        />
                        <path
                          d="M 4789 502 C 4796 465 4803 420 4806 403 L 4812 370 L 4022 370 L 3231 370 L 3211 396 C 3181 434 3147 552 3163 562 C 3170 566 3536 570 3977 570 L 4778 569 L 4789 502 Z"
                          id="left_rocker_panel" fill={this.getPartColor("left_rocker_panel")}
                        />
                        <path
                          d="M 3113 555 C 3121 548 3131 524 3135 503 C 3139 482 3152 444 3164 418 L 3187 370 L 3095 370 L 3002 370 L 2995 418 C 2992 444 2983 486 2975 512 C 2968 537 2964 561 2967 564 C 2980 577 3099 570 3113 555 Z"
                          id="left_rocker_panel" fill={this.getPartColor("left_rocker_panel")}
                        />
                        <path
                          d="M 1893 565 C 1905 560 1904 549 1889 492 C 1880 455 1869 413 1866 398 L 1860 370 L 1685 370 L 1510 370 L 1466 463 C 1442 513 1425 558 1427 563 C 1432 571 1871 573 1893 565 Z"
                          id="bumper" fill={this.getPartColor("bumper")}
                        />
                        <path
                          d="M 1924 825 C 1874 754 1835 671 1824 615 L 1817 578 L 1586 582 C 1388 585 1350 588 1322 603 L 1289 621 L 1295 713 C 1303 826 1307 850 1326 871 C 1339 886 1377 888 1655 888 L 1969 889 L 1924 825 Z"
                          id="bumper" fill={this.getPartColor("bumper")}
                        />
                        <path
                          d="M 7579 884 C 7743 860 7773 833 7774 705 C 7775 653 7770 627 7757 607 L 7740 580 L 7381 580 L 7022 580 L 7010 618 C 6992 673 6925 796 6885 844 C 6866 868 6850 890 6850 894 C 6850 897 6990 900 7161 900 C 7392 900 7500 896 7579 884 Z"
                          id="rear_bumper" fill={this.getPartColor("rear_bumper")}
                        />
                        <path
                          d="M 5930 895 C 5930 892 5911 864 5887 832 C 5864 801 5825 731 5800 677 L 5755 580 L 5283 580 L 4810 580 L 4806 603 C 4798 645 4780 819 4780 859 L 4780 900 L 5355 900 C 5671 900 5930 898 5930 895 Z"
                          id="rear_left_door" fill={this.getPartColor("rear_left_door")}
                        />
                        <path
                          d="M 4744 888 C 4746 881 4753 821 4760 755 C 4766 689 4774 623 4777 607 L 4783 580 L 3964 582 L 3146 585 L 3138 620 C 3126 681 3108 879 3115 890 C 3125 906 4740 904 4744 888 Z"
                          id="front_left_door" fill={this.getPartColor("front_left_door")}
                        />
                        <path
                          d="M 3085 833 C 3088 795 3095 729 3100 686 C 3113 580 3113 580 3086 580 C 3066 580 3059 590 3040 643 C 3012 721 2978 782 2928 842 C 2907 868 2890 891 2890 894 C 2890 897 2932 900 2984 900 L 3078 900 L 3085 833 Z"
                          id="left_fender" fill={this.getPartColor("left_fender")}
                        />
                        <path
                          d="M 6563 1034 C 6690 1001 6809 922 6887 817 C 6933 754 6983 658 6996 608 L 7002 580 L 6944 581 L 6885 581 L 6846 662 C 6797 764 6685 882 6600 922 C 6347 1039 6061 930 5922 663 L 5879 580 L 5835 580 C 5793 580 5791 581 5799 603 C 5850 732 5917 837 5995 908 C 6110 1013 6208 1048 6389 1049 C 6461 1050 6524 1044 6563 1034 Z"
                          id="left_quarter_panel" fill={this.getPartColor("left_quarter_panel")}
                        />
                        <path
                          d="M 2603 1041 C 2727 1009 2826 946 2907 848 C 2955 789 3024 662 3035 613 L 3042 580 L 2998 580 L 2955 581 L 2918 658 C 2814 879 2594 996 2348 962 C 2161 936 1995 799 1929 616 C 1917 582 1913 580 1873 580 C 1831 580 1830 581 1836 608 C 1839 623 1862 678 1887 730 C 1968 897 2104 1008 2270 1044 C 2367 1065 2512 1064 2603 1041 Z"
                          id="left_fender" fill={this.getPartColor("left_fender")}
                        />
                        <path
                          d="M 3145 1389 C 3113 1287 3091 1159 3083 1023 L 3077 910 L 2975 910 C 2876 910 2871 911 2835 940 C 2739 1017 2645 1055 2512 1071 C 2336 1093 2161 1047 2038 947 L 1980 900 L 1820 900 C 1732 900 1660 902 1660 904 C 1660 906 1676 952 1696 1006 C 1755 1167 1754 1157 1711 1164 C 1691 1167 1649 1170 1618 1170 C 1586 1170 1560 1174 1560 1179 C 1560 1192 1704 1246 1815 1274 C 1991 1318 2102 1333 2495 1365 C 2750 1386 2980 1416 3075 1440 C 3118 1451 3156 1462 3158 1465 C 3171 1477 3168 1459 3145 1389 Z"
                          id="left_fender" fill={this.getPartColor("left_fender")}
                        />
                        <path
                          d="M 5907 1970 C 6193 1924 6266 1896 6789 1635 C 7140 1460 7122 1467 7272 1435 C 7471 1392 7598 1357 7651 1329 C 7698 1305 7740 1255 7740 1223 C 7740 1198 7739 1198 7683 1204 C 7651 1208 7611 1213 7594 1216 C 7556 1223 7556 1224 7539 1043 L 7527 910 L 7178 910 L 6829 910 L 6758 960 C 6650 1036 6557 1063 6400 1064 C 6331 1064 6262 1061 6245 1057 L 6215 1049 L 6247 1075 C 6264 1089 6286 1120 6295 1145 C 6310 1183 6311 1197 6301 1236 C 6267 1369 6079 1629 5841 1873 C 5721 1996 5717 2002 5750 1996 C 5769 1993 5840 1981 5907 1970 Z"
                          id="left_quarter_panel" fill={this.getPartColor("left_quarter_panel")}
                        />
                        <path
                          d="M 4806 1883 C 4796 1666 4780 1444 4769 1370 C 4764 1334 4757 1216 4753 1108 L 4747 910 L 3928 910 L 3110 910 L 3110 981 C 3110 1113 3153 1343 3197 1450 C 3209 1479 3232 1497 3335 1557 C 3567 1693 3994 1933 4046 1957 C 4144 2002 4347 2026 4654 2029 L 4812 2030 L 4806 1883 Z"
                          id="front_left_door" fill={this.getPartColor("front_left_door")}
                        />
                        <path
                          d="M 5515 2019 C 5600 2012 5653 2003 5669 1993 C 5710 1967 5859 1819 5940 1725 C 6063 1581 6187 1411 6230 1325 C 6308 1170 6298 1140 6129 1038 C 6066 1000 6001 956 5985 939 L 5954 910 L 5367 910 C 4905 910 4780 913 4779 923 C 4773 975 4784 1209 4800 1375 C 4816 1541 4840 1919 4840 2006 C 4840 2029 4842 2029 4968 2033 C 5130 2038 5349 2032 5515 2019 Z"
                          id="rear_left_door" fill={this.getPartColor("rear_left_door")}
                        />
                        <path
                          d="M 4886 1978 C 4883 1971 4879 1877 4876 1770 C 4872 1663 4867 1542 4863 1503 L 4857 1430 L 5464 1430 C 5797 1430 6070 1434 6070 1438 C 6070 1453 5959 1623 5890 1715 C 5809 1823 5702 1928 5640 1961 C 5595 1985 5592 1985 5242 1988 C 4956 1991 4889 1989 4886 1978 Z"
                          style={{
                            fill: "rgb(255, 255, 255)"
                          }}
                        />
                        <path
                          d="M 5964 1558 C 5995 1511 6020 1469 6020 1466 C 6020 1463 5980 1460 5930 1460 L 5840 1460 L 5840 1596 L 5840 1733 L 5874 1689 C 5892 1664 5933 1605 5964 1558 Z"
                          id="rear_left_door" fill={this.getPartColor("rear_left_door")}

                        />
                        <path
                          d="M 5625 1936 C 5647 1925 5702 1879 5748 1834 L 5830 1753 L 5830 1606 L 5830 1460 L 5359 1460 L 4887 1460 L 4893 1538 C 4897 1580 4903 1693 4906 1788 L 4913 1961 L 5249 1958 C 5557 1955 5588 1953 5625 1936 Z"
                          id="rear_left_door" fill={this.getPartColor("rear_left_door")}

                        />
                        <path
                          d="M 4401 1989 C 4215 1976 4141 1957 4005 1892 C 3771 1778 3419 1563 3322 1474 L 3275 1431 L 4001 1430 L 4727 1430 L 4734 1523 C 4751 1774 4760 1890 4766 1943 L 4772 2000 L 4643 1999 C 4573 1998 4464 1993 4401 1989 Z"
                          style={{
                            fill: "rgb(255, 255, 255)"
                          }}
                        />
                        <path
                          d="M 4734 1923 C 4729 1865 4700 1489 4700 1472 C 4700 1463 4566 1460 4120 1460 L 3540 1460 L 3540 1528 L 3540 1596 L 3633 1652 C 3869 1796 4086 1905 4188 1930 C 4273 1952 4441 1966 4622 1969 L 4739 1970 L 4734 1923 Z"
                          id="front_left_door" fill={this.getPartColor("front_left_door")}
                        />
                        <path
                          d="M5938 1823 c66 -71 182 -189 256 -263 l136 -135 358 -3 357 -2 -60 36 c-165 100 -421 247 -510 292 -152 77 -534 202 -618 202 l-39 0 120 -127z m95 57 c160 -45 351 -116 447 -166 47 -25 167 -93 268 -152 l184 -107 -124 -5 c-68 -3 -201 -3 -295 0 l-173 5 -96 95 c-174 173 -359 373 -339 367 11 -4 69 -20 128 -37z"
                          style={{
                            fill: "rgb(255, 255, 255)"
                          }}
                        />

                        <path
                          d="M 2624 837 C 2888 706 2942 358 2729 155 C 2561 -6 2303 -4 2140 160 C 1980 319 1974 578 2127 741 C 2223 844 2317 883 2458 877 C 2540 873 2559 869 2624 837 Z"
                          id="front_left_wheel" fill={this.getPartColor("front_left_wheel")}
                        />
                        <path
                          d="M2360 705 c-57 -18 -124 -75 -151 -128 -26 -50 -35 -142 -20 -189 37 -113 137 -188 250 -188 113 0 215 77 247 186 20 69 14 125 -21 193 -29 57 -73 94 -145 122 -58 22 -97 23 -160 4z m206 -41 c177 -109 139 -381 -61 -440 -91 -26 -182 1 -247 74 -108 120 -73 301 72 375 76 39 164 36 236 -9z"
                          style={{
                            fill: "rgb(255, 255, 255)"
                          }}
                        />

                        <path
                          d="M 6561 843 C 6654 799 6722 733 6766 645 C 6831 514 6823 353 6746 232 C 6659 95 6484 14 6329 39 C 6142 69 5999 212 5969 399 C 5950 519 5991 649 6077 741 C 6173 844 6267 883 6406 877 C 6482 874 6509 868 6561 843 Z"
                          id="rear_left_wheel" fill={this.getPartColor("rear_left_wheel")}
                        />
                        <path
                          d="M6310 705 c-57 -18 -124 -75 -151 -128 -26 -50 -35 -142 -20 -189 37 -113 137 -188 250 -188 113 0 215 77 247 186 20 71 14 129 -23 196 -32 59 -72 92 -143 119 -58 22 -97 23 -160 4z m206 -41 c177 -109 139 -381 -61 -440 -129 -37 -262 39 -301 172 -14 49 -15 66 -4 111 16 72 62 131 130 166 76 39 164 36 236 -9z"
                          style={{
                            fill: "rgb(255, 255, 255)"
                          }}
                        />
                      </g>
                    </svg>
                  </Tab.Pane>
                  {this.partList.map((partObj, index) =>
                  (
                    <Tab.Pane eventKey={"#" + partObj[0]} key={index}>
                      {showConfidence ? partObj[1].confidences.join(", ") : ""}
                      <Carousel interval={carousalInterval}>
                        {this.accessPhotoGivenState(partObj[1]).map((image, index2) =>
                        (
                          <Carousel.Item key={index + "_" + index2}>
                            <img
                              className="d-block w-100"
                              src={service.getMediaUrl(image)}
                              alt={partObj[0]}
                            />
                          </Carousel.Item>
                        )
                        )}
                      </Carousel>

                    </Tab.Pane>
                  )
                  )}
                </Tab.Content>

                <Row className="toggleButtonsRow justify-content-right">

                  <Col md="auto">
                    <Card >
                      <Accordion >
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.handleChangeArrow}>
                          Options &nbsp;  {
                            this.state.arrowDown ? <BsCaretDownFill /> : <BsCaretUpFill />
                          }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0" >

                          <Row className="toggleButtonsRow">
                            <div className="toggleButtonDiv">
                              <Form >
                                {['checkbox'].map((type) => (
                                  <div key={`default-damage-${type}`} className="mb-3">
                                    <ToggleButton
                                      className="toggleButton"
                                      checked={this.state.displayNoDamage}
                                      type={type}
                                      id={`default-damage-${type}`}
                                      label={`Only damages`}
                                      onChange={this.handleChangeDamage}
                                    > Only Damages </ToggleButton>
                                  </div>
                                ))}
                              </Form>
                            </div>
                            <div className="toggleButtonDiv">
                              <Form >
                                {['checkbox'].map((type) => (
                                  <div key={`default-BB-${type}`} className="mb-3">
                                    <ToggleButton
                                      className="toggleButton"
                                      checked={this.state.displayBoundingBox}
                                      type={type}
                                      id={`default-BB-${type}`}
                                      label={`Show Bouding Boxes `}
                                      onChange={this.handleChangeBoundingBox}

                                    > Bounding Box</ToggleButton>
                                  </div>
                                ))}
                              </Form>
                            </div>
                            <div className="toggleButtonDiv rightButtonDiv">
                              <Form >
                                {['checkbox'].map((type) => (
                                  <div key={`default-masks-${type}`} className="mb-3">
                                    <ToggleButton
                                      className="toggleButton"
                                      checked={this.state.displayMask}
                                      type={type}
                                      id={`default-masks-${type}`}
                                      label={`Show Masks`}
                                      onChange={this.handleChangeMask}

                                    > Masks </ToggleButton>
                                  </div>
                                ))}
                              </Form>
                            </div>
                          </Row>

                        </Accordion.Collapse>
                      </Accordion>
                    </Card>
                  </Col>



                </Row>
              </Col>
              <Col sm={1} className="lateral-inner">
                {!this.state.displayOuter ?
                  <div>
                    <div className="inner-info">
                      Damage:
                    </div>
                    {
                      this.partList.map((partObj, index) =>
                      (
                        <div key={index} className="inside-lateral-inner">
                          {('#' + partObj[0] == this.state.activeKey) ?
                            (partObj[1].predicted_damage != "") ?
                              this.beautifyName(partObj[1].predicted_damage) : "----"
                            : ""}
                        </div>

                      )
                      )}
                    <div className="inner-info">
                      Sizes:
                    </div>
                    {
                      this.partList.map((partObj, index) =>
                      (
                        <div key={index} className="inside-lateral-inner">
                          {('#' + partObj[0] == this.state.activeKey) ?
                            partObj[1].damage_measures.map(damage => (
                              <li>{damage}</li>
                            )) : ""}
                        </div>

                      )
                      )}
                    <div className="inner-info">
                      Action:
                    </div>
                    {
                      this.partList.map((partObj, index) =>
                      (
                        <div key={index} className="inside-lateral-inner">
                          {('#' + partObj[0] == this.state.activeKey) ?
                            (partObj[1].predicted_action != "") ?
                              this.beautifyName(partObj[1].predicted_action) : "----"
                            : ""}
                        </div>

                      )
                      )}
                  </div> : null}
              </Col>
            </Tab.Container>
          </Row >

          <Row className="mb-9 justify-content-center">

            <Col sm={2}>
            </Col>
            <Col sm={3}>
              <Button onClick={() => this.showAssessmentSummary()} className="pl-5 pr-5 ml-auto mr-auto d-flex"><b>Show Assessment </b></Button>
            </Col>
            <Col sm={3}>
              <Button onClick={() => this.showAssessmentDocuments()} className="pl-5 pr-5 ml-auto mr-auto d-flex"><b>Show Documents</b></Button>
            </Col>

          </Row>

          <Row className="mb-9  justify-content-center">
            &nbsp;
          </Row>

        </Container >

        <Modal
          show={this.state.modalShow}
          onHide={() => this.hideAssessmentSummary()}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Assessment Summary
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <thead>
                <tr>
                  <th>Part Name</th>
                  <th>Action</th>
                  <th>Estimated Cost</th>
                  <th>Estimated Time</th>
                </tr>
              </thead>
              <tbody>
                {this.damagedParts.map((partObj, index) =>
                (
                  <tr key={index}>
                    <td>{this.beautifyName(partObj[0])}</td>
                    <td>{this.beautifyName(partObj[1].overall_damage_class)}</td>
                    <td>{this.beautifyName(partObj[2])}</td>
                    <td>{this.beautifyName(partObj[3])}</td>
                  </tr>
                )
                )}
                <tr>
                  <td></td>
                  <td><b>Total: </b></td>
                  <td><b>{priceDict.currency + " " + this.totalCost}</b></td>
                  <td><b>{this.totalTime + " " + timeDict.unit}</b></td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.hideAssessmentSummary()}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalDocumentShow}
          id=""
          onHide={() => this.hideAssessmentDocuments()}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Extracted Info from Documents
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table id="info-from-documents">

              <thead>
                <tr>
                  <th>Image</th>
                  <th>Info</th>

                </tr>
              </thead>
              <tbody>

                <tr>
                  <td>
                    <div class="mb-3">
                      <img
                        width="300px"
                        src={this.documentResult['photo_url'] === undefined ? "http://placehold.jp/22/d6d6d6/575c61/300x180.png?text=Not%20detected%20in%20photos" :
                          service.getMediaUrl(this.documentResult['photo_url'])}
                      />
                    </div>

                  </td>
                  <td> <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">DNI</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      placeholder={this.documentResult['dni']}
                      aria-label="DNI"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder={this.documentResult['nombre']}
                        aria-label="Name"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Surname(s)</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder={this.documentResult['apellidos']}
                        aria-label="Surname"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Birthdate</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder={this.documentResult['fecha_nacimiento']}
                        aria-label="Birthdate"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>

                  </td>
                </tr>


                <tr>
                  <td>
                    <div class="mb-3">
                      <img
                        width="300px"
                        src={this.license_plate[0] === undefined ? "http://placehold.jp/22/d6d6d6/575c61/300x180.png?text=Not%20detected%20in%20photos" :
                          service.getMediaUrl(this.license_plate[0][1].images[0].replace("burned.jpg", "cropped.jpg"))}
                      />
                    </div>

                  </td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">License plate</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder={this.documentResult['license_plate']}
                        aria-label="License plate"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Country</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder={this.documentResult['license_plate_country']}
                        aria-label="Country"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Make</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder={this.documentResult['make']}
                        aria-label="Make"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Model</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder={this.documentResult['model']}
                        aria-label="Model"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>


                  </td>
                </tr>


                <tr>
                  <td>
                    <div class="mb-3">
                      <img
                        width="300px"
                        src={this.vinResult.vin_info.vin_image_url === undefined ? "http://placehold.jp/22/d6d6d6/575c61/300x180.png?text=Not%20detected%20in%20photos" :
                          service.getMediaUrl(this.vinResult.vin_info.vin_image_url)}
                      />
                    </div>

                  </td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">VIN</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        placeholder={this.vinResult.vin_info.text}
                        aria-label="VIN"
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                  </td>
                </tr>


              </tbody>
            </Table>

            <Accordion defaultActiveKey="1">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  Show JSON
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body id="json"><pre>
                    {JSON.stringify(this.documentResult, null, 2)}
                  </pre></Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.hideAssessmentDocuments()}>Close</Button>
            <Button onClick={() => this.printDocument()}>Download as PDF<BsArrowDown /></Button>
          </Modal.Footer>
        </Modal>
      </div >
    );
  }
}

export default Damage;
