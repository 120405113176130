import { Component } from "react";
import { Redirect } from "react-router-dom";

import BasicDropzone from '../Dropzone/Dropzone';
import BasicNavbar from '../Navbar/Navbar';

import service from "../../services/service"


class Dash extends Component {
  state = {};


  async componentDidMount() {
    // Fetches token from localstorage on component mount
    const token = service.fetchToken();
    let res = await service.getUserInfo();
    this.setState({
      "token": token,
      "fetched": true,
      "user": res.data
    });


  }

  render() {
    const { token, fetched } = this.state;

    // If token is not available, redirect to login
    if (fetched && !token) {
      return <Redirect to="/login/" />;
    }

    return (
      <div>
        <BasicNavbar />
        <div>
          Aquí va a haber un peaso dashboard de flipar ✨ {console.log(this.state.user)}
        </div>
      </div>
    );
  }
}

export default Dash;
