import axios from 'axios';

import { serverUrl, tokenKey, mediaUrl } from "../config.json";


export function fetchToken() {
  // Fetches token from localstorage

  try {
    const token = localStorage.getItem(tokenKey);
    return token;
  } catch (e) {
    console.log('Error while fetching token', e);
    return null;
  }
}

export function setToken(token) {
  // Sets token in localstorage

  localStorage.setItem(tokenKey, token);
}

export function removeToken() {
  // Removes token from localstorage

  localStorage.removeItem(tokenKey);
}

export async function login(username, password) {
  // API call to log in user
  // Params: username (str) and password(str)
  // Login Post request, sets token in localstorage, Returns the response

  const res = await axios.post(serverUrl + "/token/", { username: username, password: password });
  setToken(res.data.token);
  return res;
}

export async function uploadImages(files) {
  // Takes into input array of files
  // Creates a mutlipart/form-data type of request
  // and uplaods the images

  let formData = new FormData();
  for (var i = 0; i < files.length; i++) {
    formData.append('file_' + i, files[i]);
  }

  console.log(formData);
  const res = await axios.post(serverUrl + "/upload/", formData, {
    headers: {
      'content-type': 'multipart/form-data',
      'Authorization': `Token ${fetchToken()}`
    }
  })
  return res
}

export async function classifyImages(batchId) {
  // Calls the /classify/ api with given batchId
  const res = await axios.post(serverUrl + "/classify/", { batch_id: batchId }, {
    headers: {
      'Authorization': `Token ${fetchToken()}`
    }
  })
  return res
}

export async function photoQuality(batchId) {
  // Calls the /quality/ api with given batchId
  const res = await axios.post(serverUrl + "/quality/", { batch_id: batchId }, {
    headers: {
      'Authorization': `Token ${fetchToken()}`
    }
  })
  return res
}

export async function getUserInfo() {
  // Calls the /classify/ api with given batchId
  const headers = {
    'Authorization': `Token ${fetchToken()}`
  };
  const res = axios.get(serverUrl + "/userinfo/", { headers });
  return res
}

export async function assessPhotos(batchId, photos = []) {
  // Calls the /damage/ api with given batchId, and list of photos
  // List of photos is optionally passed to updated image classification classes
  console.log(photos);
  let res_damage = null;
  let res_document = null;
  let res_interior_damage = null;
  let res_vin = null;

  await axios.all([
    axios.post(serverUrl + "/damage/", { batch_id: batchId, photos }, {
      headers: {
        'Authorization': `Token ${fetchToken()}`
      }
    }),
    axios.post(serverUrl + "/dni_info/", { batch_id: batchId, photos }, {
      headers: {
        'Authorization': `Token ${fetchToken()}`
      }
    }),
    axios.post(serverUrl + "/interior-damage/", { batch_id: batchId }, {
      headers: {
        'Authorization': `Token ${fetchToken()}`
      }
    }),
    axios.post(serverUrl + "/vin_info/", { batch_id: batchId }, {
      headers: {
        'Authorization': `Token ${fetchToken()}`
      }
    })
  ])
    .then(axios.spread((data1, data2, data3, data4) => {
      // output of req.
      console.log('data1', data1, 'data2', data2, 'data3', data3, 'data4', data4);
      res_damage = data1;
      res_document = data2;
      res_interior_damage = data3;
      res_vin = data4;
    }));

  return [res_damage, res_document, res_interior_damage, res_vin];
}

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function getMediaUrl(relPath) {
  // Return MEDIA url from relative path of an image
  console.log(mediaUrl + relPath);
  if (isValidHttpUrl(relPath)) {
    return relPath;
  } else {
    return mediaUrl + relPath;
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  fetchToken,
  setToken,
  removeToken,
  login,
  uploadImages,
  classifyImages,
  photoQuality,
  assessPhotos,
  getMediaUrl,
  getUserInfo
};
