// Hard coded time for type of action, corresponding to different parts

let timeDict = {
  unit: "Hrs",
  bonnet: { damage: 4, major_repair: 2, minor_repair: 1 },
  bumper: { damage: 4, major_repair: 2, minor_repair: 1 },
  front_left_wheel: { damage: 4, major_repair: 2, minor_repair: 1 },
  front_license_plate: { damage: 4, major_repair: 2, minor_repair: 1 },
  front_right_door: { damage: 4, major_repair: 2, minor_repair: 1 },
  front_right_wheel: { damage: 4, major_repair: 2, minor_repair: 1 },
  front_windshield: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_a_pillar: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_fender: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_headlamp: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_mirror: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_quarter_panel: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_reflector: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_rocker_panel: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_roof_rail: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_taillamp: { damage: 4, major_repair: 2, minor_repair: 1 },
  left_wiper: { damage: 4, major_repair: 2, minor_repair: 1 },
  lower_bumper_grill: { damage: 4, major_repair: 2, minor_repair: 1 },
  petrol_cap: { damage: 4, major_repair: 2, minor_repair: 1 },
  rear_bumper: { damage: 4, major_repair: 2, minor_repair: 1 },
  rear_left_door: { damage: 4, major_repair: 2, minor_repair: 1 },
  rear_left_wheel: { damage: 4, major_repair: 2, minor_repair: 1 },
  rear_license_plate: { damage: 4, major_repair: 2, minor_repair: 1 },
  rear_right_door: { damage: 4, major_repair: 2, minor_repair: 1 },
  rear_right_wheel: { damage: 4, major_repair: 2, minor_repair: 1 },
  rear_windshield: { damage: 4, major_repair: 2, minor_repair: 1 },
  rear_windshield_taillight: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_a_pillar: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_fender: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_headlamp: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_mirror: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_quarter_panel: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_reflector: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_rocker_panel: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_roof_rail: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_taillamp: { damage: 4, major_repair: 2, minor_repair: 1 },
  right_wiper: { damage: 4, major_repair: 2, minor_repair: 1 },
  trunk: { damage: 4, major_repair: 2, minor_repair: 1 },
  upper_bumper_grill: { damage: 4, major_repair: 2, minor_repair: 1 },
};

export default timeDict;
