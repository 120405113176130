import { Component } from "react";
import { Spinner } from "react-bootstrap";

import "./LoadingSpinner.css"

class LoadingSpinner extends Component {
  
  render() {
    return (
      <div className="loading-container">
        <h2>Processing &nbsp;</h2><Spinner animation="border" variant="success" size="lg" />
      </div>
    );
  }
}

export default LoadingSpinner;
