import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "./components/Login/Login";
import Logout from "./components/Logout/Logout";
import Home from "./components/Home/Home";
import Classified from "./components/Classified/Classified";
import Damage from "./components/Damage/Damage";
import Dash from "./components/Dash/Dash";
import BlankComponent from "./components/BlankComponent/BlankComponent";

import "./App.css";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route path="/classified" component={Classified} />
            <Route path="/damage" component={Damage} />
            <Route path="/dashboard" component={Dash} />
            <Route path="/blank" component={BlankComponent} />
            <Route path="/" component={Home} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
