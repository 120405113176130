import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Container, Row, CardColumns, Card, Alert, Accordion, Spinner, Col } from 'react-bootstrap';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import BasicNavbar from '../Navbar/Navbar';
import service from "../../services/service"
import { ProgressTracker, Stages } from '@atlaskit/progress-tracker';

import { showConfidence, showPhotoQuality } from "../../config.json";

import './Classified.css'

class Classified extends Component {
  constructor(props) {
    super(props);


    let result = this.props.location.result;

    if (result) {
      localStorage.setItem('damageResult', JSON.stringify(result));
    } else {
      result = JSON.parse(localStorage.getItem('damageResult'));
    }

    let interiordamageresult = this.props.location.interiordamageresult;

    if (false) {
      if (interiordamageresult) {
        localStorage.setItem('interiorDamageResult', JSON.stringify(interiordamageresult));
      } else {
        try {
          interiordamageresult = JSON.parse(localStorage.getItem('interiorDamageResult'));
        }
        catch (err) {
          interiordamageresult = null;
        }

      }
    }

    this.state = {
      result: result,
      batchId: result[0].batch,
      renderDamage: false,
      loading: false,
      showError: false
    }

    this.imageClasses = ['car_outer', 'car_internal', 'chassis_engine_number', 'speedometer_odometer', 'document', 'car_dashboard', 'other', 'car_interior']
    this.beautifiedNames = {
      'car_outer': 'Car Outer',
      'car_internal': 'Car Internal',
      'chassis_engine_number': 'Chassis/Engine Number',
      'speedometer_odometer': 'Speedometer/Odometer',
      'document': 'Document',
      'car_dashboard': 'Car Dashboard',
      'other': 'Other',
      'car_interior': 'Car Interior',
    }
    this.items = [
      {
        id: 'current-1',
        label: 'Upload images',
        percentageComplete: 100,
        status: 'visited',
        href: '/',
      },
      {
        id: 'unvisited-1',
        label: 'Classify images',
        percentageComplete: 0,
        status: 'current',
        href: '#',
      },
      {
        id: 'unvisited-2',
        label: 'Assess damage',
        percentageComplete: 0,
        status: 'unvisited',
        href: '#',
      },
    ];
  }

  async assessDamage() {
    // Calls assessDamage with this.state.batchId, and this.state.result
    // this.state.result holds updated imageClass values for images which
    // are passed on to the API to run with updated values of classes
    this.setState({ loading: true })
    try {
      let res_array = await service.assessPhotos(this.state.batchId, this.state.result);
      let res = res_array[0];
      let res_document = res_array[1];
      let res_interior_damage = res_array[2];
      let res_vin = res_array[3];

      console.log(res_document);
      this.setState({
        renderDamage: true,
        damageRes: res.data,
        documentRes: res_document.data,
        interiorDamageRes: res_interior_damage.data,
        vinRes: res_vin.data,
        loading: false
      })
    } catch (err) {
      this.setState({
        loading: false,
        showError: true
      })
    }
  }

  changeImageClass(index, e) {
    // imageClass change handler
    // updated the class at index with new value

    let result = this.state.result;
    result[index].category = e.target.value
    this.setState({
      result
    })
  }

  render() {
    console.log(this.state)

    // If to renderDamage, redirects to /damage
    if (this.state.renderDamage) {
      return <Redirect to={{
        pathname: "/damage", result: this.state.damageRes, documentresult: JSON.stringify(this.state.documentRes),
        interiordamageresult: this.state.interiorDamageRes, vinresult: JSON.stringify(this.state.vinRes)
      }} />
    }

    // If in loading state, return Loader
    //if (this.state.loading)
    //  return (
    //    <>
    //      <BasicNavbar />
    //      <LoadingSpinner />
    //    </>
    // )

    // Filters Images with class of type Car Images (car_outer)
    let carImages = this.state.result.filter(function (imgObj) {
      return imgObj.category === "car_outer" || imgObj.category === "car_interior";
    });

    // Renders components corresponding to Car Images
    let carImagesCol = (
      <Card style={{ width: "100%" }}>
        <Accordion defaultActiveKey="0">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Images to Assess Damage (Exterior)
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <CardColumns style={{ columnCount: 4 }}>
                {this.state.result.map((imgObj, index) => (
                  <span key={index}>
                    {(imgObj.category === "car_outer") ?
                      <Card className="col-auto mb-3 pt-3 pb-3" style={{ maxWidth: "14rem" }} key={"car_" + index}>
                        <Card.Img variant="top" src={service.getMediaUrl(imgObj.image)} style={{ height: "10rem", width: "100%" }} />
                        <select className="classifier-select" defaultValue={imgObj.category} onChange={(e) => { this.changeImageClass(index, e) }}>
                          {
                            this.imageClasses.map((imageClass, index2) => (
                              <option value={imageClass} key={index + "_" + index2}>{this.beautifiedNames[imageClass]}</option>
                            )
                            )
                          }
                        </select>
                        {showConfidence ? "Confidence: " + imgObj.confidence.toFixed(2) : ""} <br></br>
                        {showPhotoQuality ? "Photo Quality: " + imgObj.photo_quality : ""}
                      </Card>
                      :
                      <span key={"car_" + index}></span>
                    }
                  </span>
                ))}
              </CardColumns>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Card>
    )

    // Renders components corresponding to Car Images
    let interiorImagesCol = (
      <Card style={{ width: "100%" }}>
        <Accordion defaultActiveKey="0">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Images to Assess Damage (Interior)
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <CardColumns style={{ columnCount: 4 }}>
                {this.state.result.map((imgObj, index) => (
                  <span key={index}>
                    {(imgObj.category === "car_interior") ?
                      <Card className="col-auto mb-3 pt-3 pb-3" style={{ maxWidth: "14rem" }} key={"car_" + index}>
                        <Card.Img variant="top" src={service.getMediaUrl(imgObj.image)} style={{ height: "10rem", width: "100%" }} />
                        <select className="classifier-select" defaultValue={imgObj.category} onChange={(e) => { this.changeImageClass(index, e) }}>
                          {
                            this.imageClasses.map((imageClass, index2) => (
                              <option value={imageClass} key={index + "_" + index2}>{this.beautifiedNames[imageClass]}</option>
                            )
                            )
                          }
                        </select>
                        {showConfidence ? "Confidence: " + imgObj.confidence.toFixed(2) : ""} <br></br>
                        {showPhotoQuality ? "Photo Quality: " + imgObj.photo_quality : ""}
                      </Card>
                      :
                      <span key={"car_" + index}></span>
                    }
                  </span>
                ))}
              </CardColumns>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Card>
    )

    let documentImagesCol = (
      <Card style={{ width: "100%" }}>
        <Accordion defaultActiveKey="0">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Documents to Extract Info
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <CardColumns style={{ columnCount: 4 }}>
                {this.state.result.map((imgObj, index) => (
                  <span key={index}>
                    {(imgObj.category === "document") ?
                      <Card className="col-auto mb-3 pt-3 pb-3" style={{ maxWidth: "14rem" }} key={"car_" + index}>
                        <Card.Img variant="top" src={service.getMediaUrl(imgObj.image)} style={{ height: "10rem", width: "100%" }} />
                        <select className="classifier-select" defaultValue={imgObj.category} onChange={(e) => { this.changeImageClass(index, e) }}>
                          {
                            this.imageClasses.map((imageClass, index2) => (
                              <option value={imageClass} key={index + "_" + index2}>{this.beautifiedNames[imageClass]}</option>
                            )
                            )
                          }
                        </select>
                        {showConfidence ? "Confidence: " + imgObj.confidence.toFixed(2) : ""}
                      </Card>
                      :
                      <span key={"car_" + index}></span>
                    }
                  </span>
                ))}
              </CardColumns>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Card>
    )

    // Renders components corresponding to Other Images (Non car)
    let otherImagesCol = (
      <Card style={{ width: "100%" }}>
        <Accordion defaultActiveKey="0">
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Other Images
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <CardColumns style={{ columnCount: 4 }}>
                {this.state.result.map((imgObj, index) => (
                  <span key={index}>
                    {(imgObj.category === "car_outer" || imgObj.category === "car_interior" || imgObj.category === "document") ?
                      <span key={"car_" + index}></span>
                      :
                      <Card className="col-auto mb-3 pt-3 pb-3" style={{ maxWidth: "14rem" }} key={"car_" + index}>
                        <Card.Img variant="top" src={service.getMediaUrl(imgObj.image)} style={{ height: "10rem", width: "100%" }} />
                        <select className="classifier-select" defaultValue={imgObj.category} onChange={(e) => { this.changeImageClass(index, e) }}>
                          {
                            this.imageClasses.map((imageClass, index2) => (
                              <option value={imageClass} key={index + "_" + index2}>{this.beautifiedNames[imageClass]}</option>
                            )
                            )
                          }
                        </select>
                        {showConfidence ? "Confidence: " + imgObj.confidence.toFixed(2) : ""}
                      </Card>
                    }
                  </span>
                ))}
              </CardColumns>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </Card>
    )

    return (
      <div>
        <BasicNavbar />
        {this.state.showError ?
          <Alert variant="danger">
            Something went wrong, please contact system administrator
          </Alert>
          : <></>
        }
        <Container>

          <ProgressTracker items={this.items} />
          <Row className="mt-4">
            {carImagesCol}
          </Row>
          <Row className="mt-4">
            {interiorImagesCol}
          </Row>
          <Row className="mt-5 mb-5">
            {documentImagesCol}
          </Row>
          <Row className="mt-5 mb-5">
            {otherImagesCol}
          </Row>
          <Col sm={3}>
            {this.state.loading ?
              <Button onClick={() => this.assessDamage()} className="pl-5 pr-5 ml-auto mr-auto d-flex" disabled={carImages.length === 0} block><Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /></Button>
              : <Button onClick={() => this.assessDamage()} className="pl-5 pr-5 ml-auto mr-auto d-flex" disabled={carImages.length === 0} block><b className="text-center">Assess Images</b></Button>
            }
          </Col>
          <Row className="mb-9  justify-content-center">
            &nbsp;
          </Row>
        </Container>
      </div>
    );
  }
}

export default Classified;
