import { Component } from "react";
import { Navbar, Button } from "react-bootstrap";

import service from "../../services/service";

import { ProgressTracker, Stages } from '@atlaskit/progress-tracker';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import userlogo from "../../users-logo";



import "./Navbar.css";



class BasicNavbar extends Component {

  state = {};

  constructor() {
    super();

    this.state = {
      currentPosition: 0,
      logo: undefined,
      url: undefined,
    }

    this.items = [
      {
        id: 'current-1',
        label: 'Upload',
        percentageComplete: 0,
        status: 'current',
        href: '#',
      },
      {
        id: 'unvisited-1',
        label: 'Classify',
        percentageComplete: 0,
        status: 'unvisited',
        href: '#',
      },
      {
        id: 'unvisited-2',
        label: 'Assess',
        percentageComplete: 0,
        status: 'unvisited',
        href: '#',
      },
    ];

    this.doSubmit();

  }

  doSubmit = async () => {
    try {
      const res = await service.getUserInfo();
      if (res.status === 200) {
        this.setState({
          logo: userlogo[res.data.username][0],
          url: userlogo[res.data.username][1],
        });
      } else {
        console.log()
      }
    } catch (e) {
      console.log(e)
    }
  };


  componentDidMount() {
    // Fetches token from localstorage on component mount
    console.log(window.location.href);
    const token = service.fetchToken();
    this.setState({
      "token": token,
      "fetched": true
    });
  }

  render() {
    const { token, fetched } = this.state;
    let logoutButton = <p></p>
    let track = <p></p>

    // Show Logout Button only if Token is available
    if (fetched && token) {
      logoutButton = (<Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          <Button href="/" >Vision</Button>
        </Navbar.Text>


        <Navbar.Text>
          <Button href="/logout" variant="outline-primary">Logout</Button>
        </Navbar.Text>
      </Navbar.Collapse>
      )

    }

    if (fetched && token) {
      track = (
        <Navbar.Collapse className="justify-content-right">
          <ProgressTracker items={this.items} spacing="default" />
        </Navbar.Collapse>

      )

    }


    return (
      < Navbar bg="#ccf7cb" >
        <Navbar.Brand >
          <a href="/">
            <img src="header.png" height="45" className="d-inline-block align-top" />
          </a>
          {
            (this.state.logo !== undefined) ?
              <a href={this.state.url}><img src={this.state.logo} height="45" alt="user logo" className="d-inline-block align-top user-logo" /></a>
              : ""
          }
        </Navbar.Brand>

        {logoutButton}
      </Navbar >
    );
  }
}

export default BasicNavbar;
