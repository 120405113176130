// Hard coded pricing for type of action, corresponding to different parts

let priceDict = {
  currency: "€",
  bonnet: { damage: 454, major_repair: 422, minor_repair: 162 },
  bumper: { damage: 452, major_repair: 252, minor_repair: 142 },
  front_left_door: { damage: 190, major_repair: 336, minor_repair: 440 },
  front_left_wheel: { damage: 426, major_repair: 276, minor_repair: 214 },
  front_license_plate: { damage: 284, major_repair: 266, minor_repair: 260 },
  front_right_door: { damage: 315, major_repair: 345, minor_repair: 389 },
  front_right_wheel: { damage: 469, major_repair: 320, minor_repair: 489 },
  front_windshield: { damage: 165, major_repair: 168, minor_repair: 189 },
  left_a_pillar: { damage: 164, major_repair: 230, minor_repair: 270 },
  left_fender: { damage: 312, major_repair: 352, minor_repair: 215 },
  left_headlamp: { damage: 316, major_repair: 300, minor_repair: 200 },
  left_mirror: { damage: 160, major_repair: 380, minor_repair: 398 },
  left_quarter_panel: { damage: 344, major_repair: 448, minor_repair: 220 },
  left_reflector: { damage: 321, major_repair: 462, minor_repair: 330 },
  left_rocker_panel: { damage: 319, major_repair: 430, minor_repair: 150 },
  left_roof_rail: { damage: 479, major_repair: 370, minor_repair: 180 },
  left_taillamp: { damage: 225, major_repair: 340, minor_repair: 280 },
  left_wiper: { damage: 350, major_repair: 210, minor_repair: 170 },
  lower_bumper_grill: { damage: 160, major_repair: 360, minor_repair: 260 },
  petrol_cap: { damage: 180, major_repair: 260, minor_repair: 220 },
  rear_bumper: { damage: 180, major_repair: 200, minor_repair: 150 },
  rear_left_door: { damage: 256, major_repair: 490, minor_repair: 334 },
  rear_left_wheel: { damage: 360, major_repair: 450, minor_repair: 440 },
  rear_license_plate: { damage: 316, major_repair: 468, minor_repair: 300 },
  rear_right_door: { damage: 180, major_repair: 460, minor_repair: 220 },
  rear_right_wheel: { damage: 200, major_repair: 470, minor_repair: 450 },
  rear_windshield: { damage: 420, major_repair: 460, minor_repair: 165 },
  rear_windshield_taillight: {
    damage: 208,
    major_repair: 249,
    minor_repair: 370,
  },
  right_a_pillar: { damage: 340, major_repair: 160, minor_repair: 220 },
  right_fender: { damage: 340, major_repair: 290, minor_repair: 444 },
  right_headlamp: { damage: 320, major_repair: 490, minor_repair: 400 },
  right_mirror: { damage: 216, major_repair: 230, minor_repair: 150 },
  right_quarter_panel: { damage: 299, major_repair: 240, minor_repair: 180 },
  right_reflector: { damage: 160, major_repair: 400, minor_repair: 300 },
  right_rocker_panel: { damage: 150, major_repair: 350, minor_repair: 301 },
  right_roof_rail: { damage: 270, major_repair: 200, minor_repair: 350 },
  right_taillamp: { damage: 160, major_repair: 500, minor_repair: 460 },
  right_wiper: { damage: 200, major_repair: 150, minor_repair: 131 },
  trunk: { damage: 340, major_repair: 300, minor_repair: 270 },
  upper_bumper_grill: { damage: 139, major_repair: 98, minor_repair: 104 },
};

export default priceDict;
