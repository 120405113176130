import { Component } from "react";
import { Redirect } from "react-router-dom";

import BasicDropzone from '../Dropzone/Dropzone';
import BasicNavbar from '../Navbar/Navbar';

import service from "../../services/service"


class Home extends Component {
  state = {};

  componentDidMount() {
    // Fetches token from localstorage on component mount
    const token = service.fetchToken();
    this.setState({
      "token": token,
      "fetched": true
    });

    // To enable navigation back to this page via browser's back button
    if (token) {
      this.props.history.push('/')
    }
  }

  render() {
    const { token, fetched } = this.state;

    // If token is not available, redirect to login
    if (fetched && !token) {
      return <Redirect to="/login/" />;
    }

    return (
      <div>
        <BasicNavbar />
        <div>
          <BasicDropzone />
        </div>
      </div>
    );
  }
}

export default Home;
