import { Component } from "react";
import { Redirect } from "react-router-dom";

import BasicDropzone from '../Dropzone/Dropzone';
import BasicNavbar from '../Navbar/Navbar';

import service from "../../services/service"


class BlankComponent extends Component {
  state = {};

  componentDidMount() {
    // Fetches token from localstorage on component mount
    const token = service.fetchToken();
    this.setState({
      "token": token,
      "fetched": true
    });


  }

  render() {
    const { token, fetched } = this.state;

    // If token is not available, redirect to login
    if (fetched && !token) {
      return <Redirect to="/login/" />;
    }

    return (
      <div>
        <BasicNavbar />
        <div>
          A blank component, ready to be filled! For example, let's embed another app.
        </div>
      </div>
    );
  }
}

export default BlankComponent;
