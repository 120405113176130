import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Redirect } from "react-router-dom";
import { Alert, Button, Container, Row, Col, CardColumns, Card, Spinner } from 'react-bootstrap';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import service from "../../services/service";

import { maxFiles, showDamageButton } from "../../config.json";

import { ProgressTracker, Stages } from '@atlaskit/progress-tracker';

import { Progress } from 'antd';

import NProgress from 'nprogress';



import './Dropzone.css'

class BasicDropzone extends Component {
  constructor() {
    super();

    this.state = {
      files: [],
      renderClassifier: false,
      renderDamage: false,
      loading: false,
      buttonsDisabled: true,
      showError: false,
      errorMessage: "Photo upload limit reached, please contact system administrator.",
      limitsFetched: false
    };

    this.items = [
      {
        id: 'current-1',
        label: 'Upload images',
        percentageComplete: 0,
        status: 'current',
        href: '#',
      },
      {
        id: 'unvisited-1',
        label: 'Classify images',
        percentageComplete: 0,
        status: 'unvisited',
        href: '#',
      },
      {
        id: 'unvisited-2',
        label: 'Assess damage',
        percentageComplete: 0,
        status: 'unvisited',
        href: '#',
      },
    ];
  }




  async componentDidMount() {
    // Fetches token from localstorage on component mount
    const token = service.fetchToken();
    let res = await service.getUserInfo();
    this.imagesUsed = res.data.image_used;
    this.imageLimit = res.data.image_limit;
    this.setState({
      limitsFetched: true
    })
  }

  onDrop = (files) => {
    // onDrop handler, sets the files list to state
    files = this.state.files.concat(files);
    if (files.length > maxFiles) {
      files = files.splice(0, maxFiles)
      alert("Limited the number of uploaded files to " + (maxFiles).toString() + ".")
    }

    console.log(files);

    files = files.map((file) => (
      new File([file], `${encodeURI(file.name)}`, { type: file.type, path: file.path })
    ))

    console.log(files);


    if (files.length > 0) {
      this.setState({
        files,
        buttonsDisabled: false
      })
    }
  };

  removeAllImages = () => {
    this.setState({
      files: [],
      buttonsDisabled: true
    })
  }

  async uploadImages() {
    // handler that uploads this.state.files to server
    // and returns batchId in response

    let res = await service.uploadImages(this.state.files);

    if (!res.status === 200) {
      return false
    } else {
      return res.data
    }
  }

  async uploadImagesAndClassify() {
    // Uploads Images, creates batchId using uploadImages
    // Calls classifier on these Images
    this.setState({ loading: true })
    NProgress.start()
    NProgress.set(0.4)
    NProgress.inc()
    try {
      let data = await this.uploadImages()
      let batchId;
      if (data.success) {
        batchId = data.batch_id
      } else {
        this.setState({
          loading: false,
          showError: true,
          errorMessage: "Photo upload limit reached, please contact system administrator."
        })
        return
      }

      let res = await service.classifyImages(batchId);
      this.setState({
        loading: false,
        renderClassifier: true,
        res: res.data
      })

      console.log(res);



    } catch (e) {
      console.log("Error", e.stack);
      console.log("Error", e.name);
      console.log("Error", e.message);
      this.setState({
        loading: false,
        showError: true,
        errorMessage: e.message + "Something went wrong, please contact system administrator"
      })
    }
  }

  async uploadImagesAndAssessDamage() {
    // Uploads Images, creates batchId using uploadImages
    // Calls damage assessment on these Images
    this.setState({ loading: true })
    try {
      let data = await this.uploadImages()
      let batchId;
      if (data.success) {
        batchId = data.batch_id
      } else {
        this.setState({
          loading: false,
          showError: true,
          errorMessage: "Photo upload limit reached, please contact system administrator."
        })
        return
      }
      let res = await service.assessDamage(batchId);
      this.setState({
        loading: false,
        renderDamage: true,
        res: res.data
      })
    } catch (e) {
      this.setState({
        loading: false,
        showError: true,
        errorMessage: "Something went wrong, please contact system administrator"
      })
    }
  }

  removeFileAtIndex(index) {
    // Takes into input index > index of file to remove
    // Removes the corresponding file from this.state.files
    let files = this.state.files;
    files.splice(index, 1);
    if (files.length > 0) {
      this.setState({
        buttonsDisabled: false,
        files
      })
    } else {
      this.setState({
        buttonsDisabled: true,
        files
      })

    }
  }

  render() {

    // If to renderClassifier, redirects to the classified result component
    if (this.state.renderClassifier)
      return <Redirect to={{ pathname: "/classified", result: this.state.res }} />

    // If to renderDamage, redirects to the damage result component
    if (this.state.renderDamage)
      return <Redirect to={{ pathname: "/damage", result: this.state.res }} />

    // If to show loading, renders Loader
    //if (this.state.loading) {
    //  return <LoadingSpinner />
    //}


    // Rendering elements corresponding to each fileObject  as a Card
    let files = (<div></div>);
    if (this.state.files.length > 0) {
      files = (
        <div>
          <h4>Uploaded Files <Button variant="outline-danger" onClick={() => this.removeAllImages()}>Remove all</Button></h4>
          <CardColumns style={{ columnCount: 4 }}>
            {this.state.files.map((file, index) => (
              <Card className="col-auto mb-3 pt-3 pb-3" style={{ maxWidth: "14rem" }} key={index}>
                <span className="close" onClick={() => { this.removeFileAtIndex(index) }}>x</span>
                <Card.Img variant="top" src={URL.createObjectURL(file)} style={{ height: "10rem", width: "12rem" }} />
              </Card>
            ))}
          </CardColumns>
        </div>
      )
    }

    return (
      <>
        {this.state.showError ?
          <Alert variant="danger">
            {this.state.errorMessage}
          </Alert>
          : <></>
        }
        <ProgressTracker items={this.items} />
        <Dropzone onDrop={this.onDrop} accept={["image/jpeg", "image/png"]}>
          {({ getRootProps, getInputProps }) => (
            <Container>


              <Row className="mt-4">
                <Col>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <h4 className="dropzone-text-para">Drag 'n' drop some files here, or click to select files</h4>
                  </div>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col>
                  {files}
                </Col>
              </Row>

              <Row className="mb-5 fixed-bottom justify-content-center">
                <Col sm={4}>
                </Col>
                <Col sm={2}>
                  {this.state.loading ?
                    <Button onClick={() => this.uploadImagesAndClassify()} className="pl-5 pr-5 ml-auto mr-auto d-flex" disabled={this.state.buttonsDisabled} block><Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /></Button>
                    : <Button onClick={() => this.uploadImagesAndClassify()} className="pl-5 pr-5 ml-auto mr-auto d-flex " disabled={this.state.buttonsDisabled} block><b className="text-center">Classify Images</b></Button>
                  }

                </Col>
                {showDamageButton ?
                  <Col sm={2}>
                    {this.state.loading ?
                      <Button onClick={() => this.uploadImagesAndAssessDamage()} className="pl-5 pr-5 ml-auto mr-auto d-flex align-content-center" disabled={this.state.buttonsDisabled} block><Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /></Button>
                      : <Button onClick={() => this.uploadImagesAndAssessDamage()} className="pl-5 pr-5 ml-auto mr-auto d-flex  align-content-center" disabled={this.state.buttonsDisabled} block><b>Assess Damage</b></Button>
                    }

                  </Col>
                  :
                  <></>
                }
                <Col sm={4}>
                  <Row sm={4} classname="mb-5 align-content-right">
                    Quota used:&nbsp;
                    <Progress
                      status="active"
                      strokeColor={{
                        '0%': '#0d2276',
                        '33%': '#1640a8',
                        '66%': '#9ca1d9',
                        '100%': '#f4c337',
                      }}
                      percent={this.imagesUsed >= 0 ? Math.round(this.imagesUsed / this.imageLimit * 100) : 90}
                      format={() => `${this.imagesUsed >= 0 ? this.imagesUsed : 90} / ${this.imageLimit}`}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
        </Dropzone>
      </>
    );
  }
}

export default BasicDropzone

